import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    min-height: 10em;
    
    .user-payment-methods {
        .user-payment-methods-headline {
            text-align: left;
            font-size: 1.2em;
            font-weight: 300;
            color: ${variables.dpc_fontDark};
        }
        .user-payment-methods-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1em;

            .user-payment-method {
                margin-bottom: 1em;
                margin-right: 1em;
                padding: 1em;
                background: #333333;
            }
        }
        .new-payment-method {
            display: block;
            margin-top: 2em;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .user-payment-methods {
        .user-payment-methods-headline {
            margin-top: 1em;
            font-size: 2.8em;
            font-weight: 600;
            color: ${variables.dpc_fontDark};
            text-align: left;
        }
        .user-payment-methods-list {
            margin-top: 3em;

            .user-payment-method {
                position: relative;
                margin-bottom: 1em;
                background: ${variables.dpc_backgroundBlack};
                color: ${variables.dpc_fontWhite};
                font-weight: 300;
                font-size: .9em;
                cursor: pointer;
                padding-right: 4em;
                padding-left: 1em;

                b {
                    font-weight: 600;
                }

                &:hover {
                    background-image: linear-gradient(to left, #DA3143, #FF6254);
                }

                &.selected {
                    box-shadow: 0 0 0 .1em #FF0031 inset;
                    box-shadow: 0px 0px 5px #FF003166;
                    color: ${variables.dpc_fontDark};
                    border-radius: .2em;
                    font-weight: 500;
                    transition: all .3s ease-in-out;
                    text-transform: uppercase;
                    background-image: linear-gradient(to left, #DA3143, #FF6254);

                    &:hover {
                        background-image: linear-gradient(to left, #DA3143, #FF6254);
                    }
                }
            }
        }
    }

    .payment-method-link {
        color: #707070;
        display: block;
        font-size: .9em;
        margin-top: .25em;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        text-align: link;
    }

    @media (max-width: ${variables.tabletL}) {
        .user-payment-methods {
            .user-payment-methods-headline {
                color: ${variables.dpc_fontWhite};
            }
        }
    }
    @media (max-width: ${variables.mobileL}) {
        .user-payment-methods {
            .user-payment-methods-list {
                .user-payment-method {
                    width: 46%;
                }
            }
        }
    }
    @media (max-width: ${variables.mobileM}) {
        .user-payment-methods {
            .user-payment-methods-list {
                .user-payment-method {
                    width: 100%;
                }
            }
        }
    }
`;
