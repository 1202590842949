import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`

    .error-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .content-title {
            font-size: 2.5em;
            text-align: center;
            color: ${variables.dpc_fontDark};
            margin-bottom: 1em;
        }
    }

    @media (max-width: ${variables.desktopS}) {
        .error-content {
            .content-title {
                font-size: 2em;
            }
        }
    }
    @media (max-width: ${variables.tabletS}) {
        .error-content {
            .content-title {
                font-size: 1.5em;
            }
        }
    }
    @media (max-width: ${variables.mobileM}) {
        .error-content {
            .content-title {
                font-size: 1.3em;
            }
        }
    }
`;
