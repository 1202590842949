import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { API_RESOURCE_PAYMENT_METHODS } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import UserPaymentMethodCreateForm from 'Components/adept/userPaymentMethods/CreateForm';

export default class PublicShopPaymentMethodRecurring extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUserPaymentMethods: PropTypes.func.isRequired,
        }).isRequired,
        userEmail: PropTypes.string,
        authToken: PropTypes.string,
        userPaymentMethods: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),

        paymentMethod: PropTypes.object,
        onSelectPaymentMethod: PropTypes.func.isRequired,
    };
    static defaultProps = {
        userEmail: null,
        paymentMethod: null,
    };
    state = {
        isPaymentMethodFormVisible: false,
    };

    componentDidMount = () => {
        const { actions, authToken, onSelectPaymentMethod } = this.props;

        if(authToken) {
            actions.listUserPaymentMethods().then(response => {
                const result = response.payload[API_RESOURCE_PAYMENT_METHODS];

                if (result?.elements?.length > 0) {
                    onSelectPaymentMethod(result.elements[0]);
                }
            });
        }
    }

    componentDidUpdate = prevProps => {
        const { userPaymentMethods, onSelectPaymentMethod } = this.props;

        //If payments methods has been loaded, set default one
        if (userPaymentMethods && JSON.stringify(userPaymentMethods.elements) !== JSON.stringify(prevProps.userPaymentMethods.elements)) {
            const defaultPaymentMethod = this.getDefaultPaymentMethod();

            if(defaultPaymentMethod) {
                onSelectPaymentMethod(defaultPaymentMethod);
            }
        }
    }

    getDefaultPaymentMethod = () => {
        const { userPaymentMethods } = this.props;

        //If there is no payment methods
        if (!userPaymentMethods || (userPaymentMethods && !userPaymentMethods.elements.length)) {
            return null;
        }

        // Try to find default payment method
        let defaultPaymentMethod = userPaymentMethods.elements.find(userPaymentMethod => userPaymentMethod.default);

        // If there is no one default one, set first one
        if (!defaultPaymentMethod) {
            userPaymentMethods.elements[0];
        }

        return defaultPaymentMethod;
    }

    onAddPaymentMethod = paymentMethod => {
        const { actions, onSelectPaymentMethod, authToken } = this.props;

        if(!authToken) {
            return onSelectPaymentMethod(paymentMethod);
        }

        return actions.listUserPaymentMethods()
            .then(() => {
                onSelectPaymentMethod(paymentMethod);
                this.setState({ isPaymentMethodFormVisible: false });
            });
    }

    render() {
        const { location, history, userPaymentMethods, onSelectPaymentMethod, authToken, paymentMethod, userEmail } = this.props;
        const { isPaymentMethodFormVisible } = this.state;
        const queryObject = parseQueryToObject(location.search);

        if(userPaymentMethods.elements.length === 0 && paymentMethod) {
            userPaymentMethods.isLoaded = true;
            userPaymentMethods.elements = [paymentMethod];
        }

        return (
            <StyledComponent
                className="public-shop-payment-method-recurring"
                styles={require('./styles')}
            >
                {userPaymentMethods.isLoading && (
                    <Spinner />
                )}
                {Boolean(!authToken || userPaymentMethods.isLoaded) && (
                    <>
                        {Boolean(!isPaymentMethodFormVisible && userPaymentMethods.elements.length > 0) && (
                            <div className="user-payment-methods">
                                <h4 className="user-payment-methods-headline">
                                    Zapisane karty
                                </h4>
                                <div className="user-payment-methods-list">
                                    {userPaymentMethods.elements
                                        .filter(userPaymentMethod => {
                                            if(queryObject.payment === 'failure'){
                                                return userPaymentMethod.confirmed;
                                            }
                                            return true;
                                        })
                                        .map((userPaymentMethod, index) => (
                                            <div
                                                key={userPaymentMethod.id || userPaymentMethod.maskedCard}
                                                className={classNames({
                                                    'user-payment-method': true,
                                                    selected: paymentMethod
                                                        ? userPaymentMethod.id === paymentMethod.id
                                                        : index === 0,
                                                })}
                                                onClick={() => onSelectPaymentMethod(userPaymentMethod)}
                                            >
                                                {userPaymentMethod.maskedCard}
                                            </div>
                                        ))}
                                </div>
                                {authToken && (
                                    <a
                                        className="payment-method-link"
                                        onClick={() => this.setState({ isPaymentMethodFormVisible: true })}
                                    >
                                        Dodaj nową kartę
                                    </a>
                                )}
                            </div>
                        )}
                        {Boolean(isPaymentMethodFormVisible || userPaymentMethods.elements.length === 0) && (
                            <div className="user-payment-method-create">
                                <UserPaymentMethodCreateForm
                                    location={location}
                                    history={history}
                                    onSuccess={this.onAddPaymentMethod}
                                    returnRaw={Boolean(!authToken)}
                                    email={userEmail}
                                />
                                {authToken && userPaymentMethods.elements.length > 0 && (
                                    <a
                                        className="payment-method-link"
                                        onClick={() => this.setState({ isPaymentMethodFormVisible: false })}
                                    >
                                        Wróc do wybory karty
                                    </a>
                                )}
                            </div>
                        )}
                    </>
                )}
            </StyledComponent>
        );
    }
}