import { connect } from 'react-redux';
import Component from './component';

import { list } from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({
        userOffersList: state.adeptUserOffers.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    }),
)(Component);
