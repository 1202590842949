import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .body {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .headline {
                font-size: 4em;
                color: ${variables.dpc_fontDark};
                font-weight: 900;
                line-height: 90%;
            }

            .header-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .location-headline {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.4em;
                    margin-bottom: .5em;
                    margin-right: 1em;
                    padding-right: 1em;
                    border-right: .01em solid #CCCCCC;
                }
                .product-headline {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.4em;
                    margin-bottom: .5em;
                }
            }

            .subheadline {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2em;
                padding-top: 2em;
                border-top: 3px solid ${variables.dpc_borderRed};

                .date {
                    font-size: 1.6em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 300;
                }
                .price {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .price-label {
                        font-size: 1.6em;
                        font-weight: 300;
                        color: ${variables.dpc_fontDark};
                        margin-right: .5em;
                    }
                    .price-old {
                        font-size: 1.6em;
                        font-weight: 600;
                        text-decoration: line-through;
                        color: #999999;
                    }
                    .price-current {
                        font-size: 3em;
                        color: ${variables.dpc_fontDark};
                        font-weight: 900;
                        margin-left: .2em;
                    }
                }
            }

            .column {
                &.column-time {
                    width: 60%;

                    .time-select {
                        margin-top: 4em;
                        position: relative;

                        .component-spinner {
                            position: relative;
                            margin: 3em auto;
                        }

                        .slots-container {
                            color: ${variables.dpc_fontDark};

                            .slots-category {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin: 1em 0;
                                padding: 1em 0;
                                border-bottom: 3px solid ${variables.dpc_borderRed};

                                &:last-of-type {
                                    margin-bottom: 0;
                                    padding-bottom: 0;
                                    border-bottom: none;
                                }

                                .slot-category-label {
                                    font-weight: 300;
                                    font-size: 1.5em;
                                    min-width: 6em;
                                    margin-right: 2em;
                                }
                                .slots-list {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    width: 100%;

                                    .slot {
                                        border: 1px solid #808080;
                                        border-radius: .2em;
                                        text-align: center;
                                        padding: .85em 0;
                                        max-width: 43%;
                                        width: 5em;
                                        margin-right: .7em;
                                        margin-bottom: .7em;
                                        font-size: 1.2em;
                                        cursor: pointer;

                                        &:hover {
                                            border: 1px solid ${variables.dpc_borderRed};
                                            background-color: ${variables.dpc_backgroundLightRed};
                                        }

                                        &.active {
                                            background: transparent linear-gradient(45deg, #FC2B1B 0%, #961910 100%) 0% 0% no-repeat padding-box;
                                            color: ${variables.dpc_fontWhite};
                                            box-shadow: 0px 0px 5px #FF003166;
                                            border: 1px solid #FF0031;
                                            font-weight: 600;
                                        }

                                        &.disabled {
                                            border: none;
                                            padding: .85em 0;
                                            margin: 0;
                                            color: ${variables.dpc_fontDark};
                                            cursor: default;
                                            text-align: left;
                                            width: 100%;
                                            max-width: 100%;

                                            &:hover {
                                                border: none;
                                            }
                                        }

                                        &:last-of-type {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.column-date {
                    width: 37%;

                    .headline {
                        display: none;
                        margin-bottom: .5em;
                    }

                    .react-calendar {
                        background-color: white;
                        padding: 1em;
                        border-radius: .2em;

                        * {
                            font-family: ${variables.fontPrimary};
                        }

                        .react-calendar__navigation {
                            margin-bottom: 1em;

                            .react-calendar__navigation__arrow {
                                border: none;
                                background: none;
                                font-weight: 600;
                                font-size: 4em;
                                position: relative;
                                top: -.1em;

                                &.react-calendar__navigation__prev2-button {
                                    display: none;
                                }

                                &.react-calendar__navigation__prev-button {
                                    &[disabled] {
                                        display: none;
                                    }
                                }

                                &.react-calendar__navigation__next2-button {
                                    display: none;
                                }

                                &.react-calendar__navigation__next-button {
                                    &[disabled] {
                                        display: none;
                                    }
                                }
                            }

                            .react-calendar__navigation__label {
                                border: none;
                                background: none;
                                text-transform: uppercase;
                                font-weight: 600;
                                color: #080808;
                                font-size: 2em;
                            }
                        }

                        .react-calendar__viewContainer {
                            .react-calendar__month-view  {
                                .react-calendar__month-view__weekdays {
                                    margin-bottom: .5em;

                                    .react-calendar__month-view__weekdays__weekday {
                                        text-align: center;

                                        abbr {
                                            font-weight: 600;
                                            color: #080808;
                                            text-decoration: none;
                                            font-size: 1.2em;
                                        }
                                    }
                                }

                                .react-calendar__month-view__days {
                                    .react-calendar__month-view__days__day  {
                                        border: none;
                                        background: none;
                                        font-weight: 600;
                                        color: #080808;
                                        padding: 1em .4em;
                                        font-size: 1.1em;
                                        border-radius: .2em;
                                        cursor: pointer;
                                        width: 14%;

                                        &[disabled] {
                                            color: #CCCCCC;
                                        }

                                        &.react-calendar__tile--active {
                                            background: transparent linear-gradient(45deg, #FC2B1B 0%, #961910 100%) 0% 0% no-repeat padding-box;
                                            color: white;
                                            box-shadow: 0px 0px 5px #FF003166;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            &.body-not-found {
                flex-direction: column;
                align-items: center;
                text-align: center;

                .headline {
                    font-size: 3em;
                }

                .component-button {
                    margin-top: 2em;
                    font-size: 80%;
                }
            }
        }

        .disclaimer {
            color: ${variables.dpc_fontDark};
            margin-top: 1em;
        }

        .footer {
            margin-top: 3em;
            padding-top: 3em;
            border-top: 3px solid ${variables.dpc_borderRed};

            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .column {
                text-align: right;

                .column-label {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.2em;
                    margin-bottom: .5em;
                }

                .column-value {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.4em;
                }

                &.column-location {
                    text-align: left
                }
            }
        }


        @media (max-width: ${variables.tabletL}) {
            .body {
                flex-direction: column-reverse;

                .header-info {
                    font-size: 80%;
                }
                
                .subheadline {
                    flex-wrap: wrap;

                    .date, .price {
                        width: 100%;
                    }

                    .price {
                        margin-top: 1.5em;
                        justify-content: flex-start;
                    }
                    
                }

                .column {
                    &.column-time {
                        width: 100%;

                        .headline {
                            display: none;
                        }

                        .location {
                            display: none;
                        }

                        .time-select {
                            margin-top: 0;

                            .slots-container {
                                .slots-category {
                                    .slot-category-label {}
                                    .slots-list {
                                        flex-wrap: wrap;

                                        .slot {
                                            margin-bottom: .5em;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.column-date {
                        width: 100%;
                        margin-bottom: 2em;

                        .headline {
                            display: block;
                            font-size: 2.6em;
                        }
                    }
                }
            }

            .footer {
                flex-direction: column;

                .column {
                    text-align: center !important;
                    margin-bottom: 2em;
                    width: 100%;

                    .column-label {}
                    .column-value {}

                    &.column-submit {
                        margin-top: 1em;
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .body {
                .subheadline {
                    .price {
                        .price-current {
                            font-size: 2.2em;
                        }
                    }
                }
            }
        }
    `;
