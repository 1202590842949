import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT } from 'Consts/paymentTransactions';
import { OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';
import { PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT } from 'Consts/promoCodes';

import { translateDate, getFormattedDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import PromoCodeVerifyForm from 'Components/adept/promoCodes/VerifyForm';

export default class PublicShopPaymentCheckout extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.shape({
            offerId: PropTypes.string,
            periodType: PropTypes.string.isRequired,
            totalPriceGross: PropTypes.number.isRequired,
            totalDiscountPriceGross: PropTypes.number,
            totalDiscountedPriceGross: PropTypes.number,
            durationValue: PropTypes.number,
            durationType: PropTypes.numbstringer,
            noticePeriodDurationValue: PropTypes.number,
            noticePeriodDurationType: PropTypes.string,
            offerName: PropTypes.offerName,
            user: PropTypes.object,
            lead: PropTypes.object,
            firstEvent: PropTypes.object,
            userProductId: PropTypes.string,
            selectedOffer: PropTypes.object,
            userOfferId: PropTypes.string,
        }).isRequired,
        paymentType: PropTypes.oneOf(['single', 'default']),
        paymentMethod: PropTypes.object,
        promoCode: PropTypes.object,
        onAddPromoCode: PropTypes.func.isRequired,
        nameAccusative: PropTypes.string,
        nameGenitive: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        isPending: PropTypes.bool,
        showPromoCodeForm: PropTypes.bool,
        showPaymentButton: PropTypes.bool,
    };
    static defaultProps = {
        paymentMethod: null,
        nameAccusative: 'spotkania',
        nameGenitive: 'spotkań',
        showPromoCodeForm: true,
        showPaymentButton: true,
        totalDiscountPriceGross: null,
    };
    state = {
        isPromoCodeFormVisible: false,
    };

    componentDidMount = () => {
        const { promoCode } = this.props;

        if(promoCode) {
            this.setState({
                isPromoCodeFormVisible: true,
            });
        }
    }

    onTogglePromoCodeForm = () => {
        const { onAddPromoCode } = this.props;

        onAddPromoCode(null);
        this.setState(prevState => ({
            isPromoCodeFormVisible: !prevState.isPromoCodeFormVisible,
        }));
    }

    onCodeAdd = data => {
        const { onAddPromoCode } = this.props;
        onAddPromoCode(data);
    }

    render() {
        const { 
            data, 
            paymentType, 
            paymentMethod, 
            location, 
            history, 
            promoCode, 
            onSubmit, 
            isPending, 
            showPromoCodeForm, 
            showPaymentButton, 
            nameGenitive,
            nameAccusative,
        } = this.props;
        const { isPromoCodeFormVisible } = this.state;
        const priceGross = data.totalDiscountPriceGross || data.totalPriceGross;

        let isValid = false;
        if(paymentType === PAYMENT_TRANSACTION_TYPE_DEFAULT && paymentMethod) {
            isValid = true;
        }
        if(paymentType === PAYMENT_TRANSACTION_TYPE_SINGLE) {
            isValid = true;
        }

        let totalDiscountedPriceGross = 0;
       
        if(promoCode) {
            totalDiscountedPriceGross = priceGross;

            if(promoCode.valueType === 'percent') {
                totalDiscountedPriceGross = (totalDiscountedPriceGross * (100 - promoCode.value)) / 100;
            } else if(promoCode.valueType === 'amount') {
                totalDiscountedPriceGross = totalDiscountedPriceGross - promoCode.value;
            }
            totalDiscountedPriceGross = totalDiscountedPriceGross > 0 ? totalDiscountedPriceGross : 0;
        }

        return (
            <StyledComponent
                className="public-shop-creator-payment-form"
                styles={require('./styles')}
            >
                <div className="block block-pricing">
                    <div className="block-summary">
                        <div className="block-row block-row-price">
                            <div className="block-row-label">
                                Cena
                            </div>
                            {promoCode
                                ? (
                                    <div className="block-row-value">
                                        <span className="old-price">
                                            {formatPrice(data.totalPriceGross)}
                                        </span>
                                        <span className="new-price">
                                            {formatPrice(totalDiscountedPriceGross)}
                                        </span>
                                    </div>
                                )
                                : data.totalDiscountPriceGross
                                    ? (
                                        <div className="block-row-value">
                                            <span className="old-price">
                                                {formatPrice(data.totalPriceGross)}
                                            </span>
                                            <span className="new-price">
                                                {formatPrice(data.totalDiscountPriceGross)}
                                            </span>
                                        </div>
                                    )
                                    : (
                                        <div className="block-row-value">
                                            {formatPrice(data.totalPriceGross)}
                                        </div>
                                    )
                            }
                        </div>
                        {data.offerName && (
                            <div className="block-row block-row-user">
                                <div className="block-row-label"> 
                                    Oferta:
                                </div>
                                <div className="block-row-value">
                                    {data.offerName}
                                </div>
                            </div>
                        )}
                        {data.user && (
                            <div className="block-row block-row-user">
                                <div className="block-row-label"> 
                                    Klient:
                                </div>
                                <div className="block-row-value">
                                    {getFullName(data.user, 'nameSurname').label}
                                </div>
                            </div>
                        )}
                        {data.lead && (
                            <div className="block-row block-row-user">
                                <div className="block-row-label"> 
                                    Trener:
                                </div>
                                <div className="block-row-value">
                                    {getFullName(data.lead).label}
                                </div> 
                            </div>
                        )}
                        {data.firstEvent && (
                            <div className="block-row block-row-user">
                                <div className="block-row-label"> 
                                    Data pierwszego {nameAccusative}:
                                </div>
                                <div className="block-row-value">
                                    {getFormattedDate(moment(data?.firstEvent?.startAt))}
                                </div>
                            </div>
                        )}
                        {data.frequencyMonth && (
                            <div className="block-row block-row-price">
                                <div className="block-row-label">Ilość {nameGenitive}:</div>
                                {data.periodType === OFFER_PERIOD_TYPE_RECURRING 
                                    ?  (<div className="block-row-value">{data.frequencyMonth} miesięcznie</div>)
                                    :  (<div className="block-row-value">{data.frequencyMonth} jednorazowo</div>)
                                }
                            </div>
                        )}
                        {data.periodType === OFFER_PERIOD_TYPE_RECURRING && (
                            <>
                                <div className="block-row block-row-period">
                                    {Boolean(data.durationValue) && (
                                        <div className="block-row-label">
                                            Płatność cykliczna pobierana co: {data.durationValue} {translateDate(data.durationType, data.durationValue)}
                                        </div>
                                    )}
                                    {Boolean(!data.noticePeriodDurationValue) && (
                                        <div className="block-row-value">                                        
                                            Możesz zrezygnować w dowolnym momencie
                                        </div>
                                    )}
                                </div>
                                {Boolean(data.noticePeriodDurationValue) && (
                                    <div className="block-row block-row-notice">
                                        <div className="block-row-label">
                                            {data.noticePeriodDurationValue &&
                                                `Okres wypowiedzenia: ${data.noticePeriodDurationValue} ${translateDate(data.noticePeriodDurationType, data.noticePeriodDurationValue)}`
                                            }
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {showPromoCodeForm && (
                        <div className="block block-promo-code">
                            {isPromoCodeFormVisible
                                ? promoCode
                                    ? (
                                        <div className="promo-code-wrapper">
                                            <div className="promo-code-container added">
                                                <span className="promo-code">
                                                    Dodano kod: {promoCode.code}
                                                </span>
                                                <div className="promo-code-trigger" onClick={this.onTogglePromoCodeForm}>
                                                    Usuń kod promocyjny
                                                </div>
                                            </div>
                                            <div className="promo-code-info">
                                                <span className="promo-code-description">
                                                    {promoCode.useLimitType === PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT && parseInt(promoCode.useLimitValue) === 1
                                                        ? 'Dotyczy pierwszej płatności w ramach tego zamówienia'
                                                        : promoCode.useLimitType === PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT && parseInt(promoCode.useLimitValue) > 1
                                                            ? 'Dotyczy ' + promoCode.useLimitValue + '. pierwszych płatności w ramach tego zamówienia'
                                                            : promoCode.useLimitType === PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT && parseInt(promoCode.useLimitValue) === 0
                                                                ? 'Dotyczy wszystkich płatności w ramach tego zamówienia'
                                                                : ''
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="promo-code-container">
                                            <PromoCodeVerifyForm
                                                location={location}
                                                history={history}
                                                onSuccess={this.onCodeAdd}
                                                offerId={data?.offerId || data?.selectedOffer?.id}
                                                userOfferId={data?.userOfferId}
                                            />
                                        </div>
                                    )
                                : (
                                    <div className="promo-code-trigger" onClick={this.onTogglePromoCodeForm}>
                                        Posiadasz kod promocyjny? <b> Wpisz go tutaj!</b>
                                    </div>
                                )
                            }
                        </div>
                    )}
                </div>
                {showPaymentButton && (
                    <div className="block block-confirm">
                        <Button
                            size="large"
                            layout="fullWidth"
                            style="gradient"
                            onClick={() => onSubmit({ userProductId: data?.userProductId })}
                            loading={isPending}
                            disabled={!isValid}
                        >
                            Płacę
                        </Button>
                    </div>
                )}
            </StyledComponent>
        );
    }
}