import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class AdeptPromoCodesVerifyForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            verify: PropTypes.func.isRequired,
        }).isRequired,
        offerId: PropTypes.string,
        userOfferId: PropTypes.string,
    };

    static defaultProps = {
        onSuccess: null,
        offerId: null,
        userOfferId: null,
    };

    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            code: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions, onSuccess, offerId, userOfferId } = this.props;

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return actions.verify({
            ...formState,
            offerId,
            userOfferId,
        })
            .then(response => {
                this.setState({ isPending: false });

                if (onSuccess) {
                    onSuccess(response.payload);
                }
            })
            .catch(error => {
                Logger.error('[PROMO_CODE_VERIFY] Error', error);

                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                });
            });
    }

    render() {
        const { formState, globalError, errors, isPending } = this.state;

        return (
            <StyledComponent
                className="adept-promo-codes-verify-form"
                styles={require('./styles')}
            >
                <Form
                    data={formState}
                    onStateChange={(name, value) => this.setState(prevState => ({
                        formState: {
                            ...prevState.formState,
                            [name]: value,
                        },
                    }))}
                    errors={errors}
                    globalError={globalError}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                >
                    <Input
                        name="code"
                        placeholder="Wprowadź kod promocyjny"
                        required={true}
                    />

                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                        style="newPage"
                    >
                        Dodaj
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}