import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Step } from 'react-step-progress-bar';

import StyledComponent from 'Components/core/StyledComponent';

export default class StepProgress extends Component {
    static propTypes = {
        currentStep: PropTypes.number,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                key: PropTypes.string.isRequired,
                position: PropTypes.number,
                onClick: PropTypes.func,
            })
        ).isRequired,
        type: PropTypes.oneOf([
            'progress', 'simple',
        ]),

    };
    static defaultProps = {
        startingStep: 0,
        type: 'progress',
    };

    getStepProgressType() {
        const { type, steps, currentStep } = this.props;
        const percent = currentStep * 100 / (steps.length - 1);
        const currentStepData = steps[currentStep];

        switch(type) {
            case 'progress':
                return (
                    <ProgressBar
                        percent={percent}
                        filledBackground="linear-gradient(80deg,#FF0031 0%,#A71F57 100%) 0% 0% no-repeat padding-box"
                        stepPositions={steps.map(({ position }, index) => position ? position : index * 100 / (steps.length - 1))}
                    >
                        {steps.map(({ key, label, onClick }) => (
                            <Step key={key}>
                                {({ index }) => (
                                    <div
                                        className={`indexedStep ${currentStep > index ? 'completed' : ''} ${currentStep === index ? 'active' : ''}`}
                                        onClick={onClick}
                                    >
                                        <div className="step-index">
                                            <span className="step-index-label">
                                                {currentStep > index
                                                    ? '✓'
                                                    : index + 1
                                                }
                                            </span>
                                        </div>
                                        {label && (
                                            <div className="step-label">
                                                {label}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Step>
                        ))}
                    </ProgressBar>
                );
            case 'simple':
                return (
                    <div className="style-version-simple">
                        <span className="current-step">
                            {currentStep + 1}
                        </span>
                        <div className="step-data">
                            Etap z {steps.length}
                            {currentStepData.label && (
                                <div className="step-label">
                                    {currentStepData.label}
                                </div>
                            )}
                        </div>
                    </div>
                );
        }
    }

    render() {
        const { currentStep, steps, type } = this.props;
        const percent = currentStep * 100 / (steps.length - 1);

        return (
            <StyledComponent
                styles={require('./styles')}
                className="step-progress"
            >
                <StyledComponent
                    styles={require('./step-progress.styles')}
                    className="step-progress-wrapper"
                >
                    {this.getStepProgressType()}
                </StyledComponent>
            </StyledComponent>
        );
    }
}
