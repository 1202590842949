import { connect } from 'react-redux';
import Component from './component';

import { getLocationBySlug } from 'Redux/modules/public/locations/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            getLocationBySlug: dispatch(getLocationBySlug),
        },
    }),
)(Component);