import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .header {
        text-align: center;

        .title {
            font-size: 1.2em;
            color: ${variables.dpc_fontDark};
            font-weight: 300;
        }
        .subtitle {
            font-size: .9em;
            color: ${variables.dpc_fontDark};
            font-weight: 300;
        }
    }
    .options {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;

        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 3em;
            margin: 0 1em;
            border-radius: .3em;
            position: relative;
            padding: 2em;
            transition: .2s all ease-in-out;
            width: 100%;
            max-width: 23em;
            cursor: pointer;

            .option-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                transition: .2s all ease-in-out;
                box-shadow: 0 0 0 1em rgba(0, 0, 0 .2);
                border: 1px solid black;
                border-radius: .2em;
            }

            .option-content {
                position: relative;
                z-index: 3;

                .option-title {
                    font-size: 2.6em;
                    font-weight: 900;
                    color: black;
                }
            }
            
            &.active,
            &:hover {
                transform: scale(1.05);

                .option-bg {
                    border: 3px solid #FC2B1B;
                }

                .option-content {
                    .option-title {
                        color: #FC2B1B;
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .options {
            flex-direction: column;

            .option {
                width: 100%;
                margin: 0;
                margin-bottom: 2em;
                min-height: 10em;
                max-width: 100%;
                
                &:hover {
                    transform: scale(1);


                    .option-bg {
                        background: #111111;
                    }
                }

                &.active {
                    transform: scale(1);

                    .option-bg {
                        background: rgb(255,0,49);
                        background-image: linear-gradient(to left,#DA3143,#FF6254);
                    }
                }
            }
        }
    }
`;
