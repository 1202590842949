import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background-color: ${variables.dpc_backgroundWhite};
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 2em 0;

    .layout-container {
        display: flex;
        max-width: 100%;
        height: 100%;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .progress-container {
            margin-bottom: 1em;
            padding-bottom: 1em;
            border-bottom: .01em solid #666666;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .progress-title {
                color: ${variables.dpc_fontDark};
                font-size: 1.3em;
                font-weight: 300;
                margin-left: 1.5em;
            }
        }

        .content-container {
            position: relative;
            min-height: 20em;
            width: 100%;
            padding: 2em 0;
            text-align: center;

            .header {
                .headline {
                    color: ${variables.dpc_fontDark};
                    font-size: 1em;
                    text-align: center;
                    margin-bottom: 2em;
                }
            }
            
            &.no-padding {
                padding-top: 0;
            }

            .choose-users-count-button {
                margin-top: 1em;
                height: 1em;
                
                .users-count-link {
                    color: ${variables.dpc_fontDark};
                    font-weight: 700;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .availability-button {
                margin-top: 1em;

                .availability-link {
                    color: ${variables.dpc_fontDark};
                    font-weight: 700;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
    
    .back-button {
        margin: 3em auto;
    }

    @media (max-width: ${variables.desktopXS}) {}
    @media (max-width: ${variables.tabletL}) {
        .layout-container {
            .progress-container {
                flex-direction: column;
                justify-content: flex-start;

                .progress-title {
                    margin-top: 1em;
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: ${variables.mobileL}) {}
`;

