import { connect } from 'react-redux';
import Component from './component';

import { parseQueryToObject } from 'Utils/querystring';

import { single } from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        userOffersMap: state.adeptUserOffers.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { location } = ownProps;
        const queryObject = parseQueryToObject(location.search);

        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            userOffer: stateProps.userOffersMap[queryObject.userOfferId],
        };
    }
)(Component);
