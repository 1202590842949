import { connect } from 'react-redux';
import Component from './component';

import { check as couponCheck } from 'Redux/modules/public/coupons/actions';
import { single as getProduct } from 'Redux/modules/public/products/actions';
import { create as createOrder } from 'Redux/modules/public/orders/actions';

export default connect(
    state => ({
        productsMap: state.publicProducts.mapById,
    }),
    dispatch => ({
        actions: {
            couponCheck: dispatch(couponCheck),
            getProduct: dispatch(getProduct),
            createOrder: dispatch(createOrder),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        product: stateProps.productsMap[ownProps.productId],
    })
)(Component);
