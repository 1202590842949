import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    min-height: 10em;
    padding: 2em 0;

    .type-select-container {
        .type-select-title {
            text-align: left;
            color: ${variables.dpc_fontDark};
            font-weight: 600;
            font-size: 2em;
        }

        .type-select-options {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 1em;

            .type-select-option {
                color: black;
                font-weight: 600;
                font-size: 1.4em;
                box-shadow: inset 0 0 0 1px black;
                border-radius: .2em;
                padding: 1em;
                width: 100%;
                text-align: center;
                margin-bottom: 1em;
                cursor: pointer;

                &:hover {
                    color: #FC2B1B;
                    box-shadow: inset 0 0 0 3px #FC2B1B;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }   

    .type-select-back {
        color: ${variables.redNewPageColor};
        font-size: 1.2em;
        margin-bottom: 1em;
        font-weight: 600;
        cursor: pointer;
    } 
    
    .type-container {
        padding: 2em;
        background: ${variables.dpc_backgroundWhite};
        border-radius: .5em;
        
        .type-container-title {
            text-align: center;
            font-size: 1.6em;
            color: ${variables.dpc_fontDark};
            margin-bottom: .5em;
        }
        &.type-single-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .payu-logo {
                max-width: 20em;
            }
        }
        &.type-card-container {}
    }

    @media (max-width: ${variables.tabletL}) {
        .type-select-container {
            .type-select-title {
                color: ${variables.dpc_fontWhite};
            }
        }
        
        .type-container {
            background: none;
            
            .type-container-title {
                color: ${variables.dpc_fontWhite};
            }
        }
    }
    @media (max-width: ${variables.mobileL}) {}
`;
