import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .step-progress-wrapper {
        .RSPBprogressBar {
            .RSPBstep {
                .indexedStep {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .step-index {
                        align-items: flex-end;
                        text-align: center;
                        background: transparent linear-gradient(80deg,#FF0031 0%,#A71F57 100%) 0% 0% no-repeat padding-box;
                        width: 2em;
                        height: 2em;
                        border-radius: 99em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5em;
                        margin-top: 1em;
                        cursor: pointer;

                        .step-index-label {
                            display: block;
                            color: #CCCCCC;
                            font-weight: 900;
                        }
                    }

                    .step-label {
                        color: #333333;
                        margin-top: .5em;
                        font-size: 1.2em;
                    }

                    &.completed {
                        .step-index {
                            background: #2b2b2b;
                        }
                    }
                    &.active {
                        .step-index {
                            transform: scale(1.1);

                            .step-index-label {
                                color: white;
                            }
                        }

                        .step-label {
                            color: #CCCCCC;
                        }
                    }
                }
            }
        }

        .style-version-simple {
            display: flex;
            align-items: center;
            color: ${variables.dpc_fontDark};
            font-weight: 300;
            font-size: 1em;

            .current-step {
                color: ${variables.redNewPageColor};
                font-size: 2.8em;
                font-weight: 500;
                margin-right: .12em;
            }
            .step-data {
                color: ${variables.dpc_fontDark};
                .step-label {
                    color: #999999;
                    margin-top: .5em;
                    font-size: 1em;
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .step-progress-wrapper {
            .style-version-simple {
                font-size: 1.5em;
                color: ${variables.dpc_fontWhite};

                .step-data {
                    color: ${variables.dpc_fontWhite};
                }
            }
        }
    }
`;

