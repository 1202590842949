import { connect } from 'react-redux';
import Component from './component';

import { single } from 'Redux/modules/public/trainers/actions';
import { freeSlotsByScore } from 'Redux/modules/public/events/actions';
import { freeTrainers } from 'Redux/modules/public/locations/actions';

export default connect(
    state => ({
        currentLanguage: state.user.currentLanguage,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
            freeSlotsByScore: dispatch(freeSlotsByScore),
            freeTrainers: dispatch(freeTrainers),
        },
    }),
)(Component);
