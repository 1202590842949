import * as types from './types';

import { reduxRequest as request } from 'Services/Api';

import {
    ENDPOINT_EVENTS_FREE_SLOTS_BY_SCORE,
} from 'Consts/api';

export const freeSlotsByScore = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.FREE_SLOTS_BY_SCORE,
        method: 'GET',
        path: ENDPOINT_EVENTS_FREE_SLOTS_BY_SCORE,
        params,
        requestParams: {
            locationId: params.locationId,
            productId: params.productId,
            startAt: params.startAt,
            endAt: params.endAt,
            leadId: params.leadId,
            timeInterval: params.timeInterval,
        },
    }));
};