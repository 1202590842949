import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
    PUBLIC_SHOP,
} from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class PublicShopOfferError extends Component {
    render() {
        return (
            <StyledComponent
                className="public-shop-offer-error"
                styles={require('./styles')}
            >
                <div className="error-content">
                    <h1 className="content-title">
                        Takie zamówienie nie istnieje, wróc do sklepu
                    </h1>
                    <NavLink to={PUBLIC_SHOP.path}>
                        <Button
                            style="gradient"
                            size="medium" 
                        >
                            Wróc
                        </Button>
                    </NavLink>
                </div>
            </StyledComponent>
        );
    }
}