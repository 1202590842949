import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { USER_ROLE_ADEPT } from 'Consts/userRoles';
import { LEAD_MEDIUM_SHOP } from 'Consts/leads';

import {
    PUBLIC_USER_PASSWORD_RESET,
} from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import LoginForm from 'Components/publicUser/LoginForm';
import RegisterForm from 'Components/publicUser/RegisterForm';

export default class PublicShopUserForm extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        initialFormType: PropTypes.string,
        availableFormTypes: PropTypes.array,
        shopType: PropTypes.string,

        // Redux props
        profile: PropTypes.object,
    };
    static defaultProps = {
        profile: null,
        shopType: null,
        availableFormTypes: ['login', 'register', 'confirm'],
        initialFormType: 'login',
        onComplete: () => true,
    };
    state = {
        formType: this.props.initialFormType, //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { profile } = this.props;
        
        if (profile && (profile.role == USER_ROLE_ADEPT)) {
            this.setConfirmType();
        }
    }

    componentDidUpdate = prevProps => {
        const { profile } = this.props;
        
        if (profile && !prevProps.profile) {
            this.setConfirmType();
        }
    }

    setConfirmType = () => {
        const { availableFormTypes } = this.props;

        if(availableFormTypes.includes('confirm')) {
            this.setState({ formType: 'confirm' });
        }
    }

    render() {
        const { profile, onComplete, history, shopType, availableFormTypes } = this.props;
        const { formType } = this.state;

        return (
            <StyledComponent
                className="public-shop-user-form"
                styles={require('./styles')}
            >
                {formType === 'confirm' && (
                    <div className="confirm-wrapper">
                        <div className="view-cols">
                            <div className="view-col-info">
                                <h1 className="info-headline">Potwierdź dane</h1>
                                <p className="info-subheadline">
                                    Adres e-mail: {profile.email}
                                </p>
                                <Button
                                    size="large"
                                    style="newPage"
                                    onClick={onComplete}
                                    layout="wide"
                                >
                                    Użyj tego konta
                                </Button>
                                <a
                                    className="change-view-link"
                                    onClick={() => this.setState({ formType: 'login' })}
                                >
                                    Chcesz zmienić konto? Kliknij tutaj, aby tego dokonać.
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                {formType === 'register' && (
                    <div className="register-wrapper">
                        <div className="view-cols">
                            <div className="view-col-info">
                                <h1 className="info-headline">Zarejestruj się</h1>
                                <p className="info-subheadline">
                                    Uzupełnij niezbędne dane wymagane do założenia konta
                                </p>
                                {availableFormTypes.includes('login') && (
                                    <a
                                        className="change-view-link"
                                        onClick={() => this.setState({ formType: 'login' })}
                                    >
                                        Masz już konto? Kliknij tutaj, aby się zalogować.
                                    </a>
                                )}
                            </div>
                            <div className="view-col-form">
                                <RegisterForm 
                                    onSuccess={onComplete}
                                    history={history}
                                    onFormTypeChange={() => this.setState({ formType: 'login' })}
                                    leadSource={shopType}
                                    leadMedium={LEAD_MEDIUM_SHOP}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {formType === 'login' && (
                    <div className="login-wrapper">
                        <div className="view-cols">
                            <div className="view-col-info">
                                <h1 className="info-headline">Zaloguj się</h1>
                                <p className="info-subheadline">
                                    Zaloguj się do konta, z którego chcesz złożyć zamówienie.
                                </p>
                                {availableFormTypes.includes('register') && (
                                    <a
                                        className="change-view-link"
                                        onClick={() => this.setState({ formType: 'register' })}
                                    >
                                        Nie masz jeszcze konta? Kliknij tutaj aby się zarejestrować.
                                    </a>
                                )}
                            </div>
                            <div className="view-col-form">
                                <LoginForm onSuccess={onComplete} />
                                <p className="section-disclaimer">
                                    Nie pamiętasz hasła?<br/>
                                    <NavLink className="link" to={PUBLIC_USER_PASSWORD_RESET.path}>
                                        Kliknij tutaj
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </StyledComponent>
        );
    }
}