import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background-color: ${variables.dpc_backgroundWhite};
    margin-top: 2em;

    .block {
        padding-bottom: 2em;
        border-bottom: 1px solid #CCCCCC;

        .block-headline {
            font-size: 1.6em;
            font-weight: 300;
            color: ${variables.dpc_fontDark};
        }

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        .consents {
            margin-top: 2em;
        }
    }

    @media (max-width: ${variables.tabletL}) {}
    @media (max-width: ${variables.mobileL}) {}
`;
