import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background: ${variables.dpc_backgroundWhite};
    margin-top: 2em;
    margin-bottom: 2em;

    .block {
        padding-bottom: 2em;
        border-bottom: 1px solid #CCCCCC;

        .block-headline {
            font-size: 1.6em;
            font-weight: 300;
            color: ${variables.dpc_fontDark};
        }

        .block-summary {
            margin-top: 0;

            .block-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.3em;
                margin-bottom: .5em;
                padding-bottom: .5em;


                &:not(:first-of-type) {
                    margin-bottom: 0;
                }

                .block-row-label {
                    color: #666666;
                }
                .block-row-value {
                    color: #CCCCCC;
                }

                &.block-row-price {
                    font-size: 2em;
                    font-weight: 900;

                    .block-row-label {
                        color: black;
                    }
                    .block-row-value {
                        color: ${variables.redNewPageColor};

                        .old-price {
                            color: ${variables.dpc_fontRed};
                            text-decoration: line-through;
                            font-weight: 300;
                            margin-right: .3em;
                            font-size: .8em;
                        }
                        .new-price {

                        }
                    }
                }
            }
        }

        &.block-promo-code {
            .promo-code-trigger {
                font-size: 1.2em;
                text-decoration: underline;
                cursor: pointer;
                color: #666666;
                border-radius: .2em;
            }
            
            .promo-code-wrapper {
                .promo-code-container {
                    &.added {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;

                        .promo-code {
                            font-weight: 600;
                            color: ${variables.dpc_fontDark};
                            font-size: 1.2em;
                        }
                    }
                }

                .promo-code-info {
                    font-weight: 600;
                    color: white;
                    font-size: 1.2em;
                    display: flex;
                    flex-direction: column;
                    margin-top: 1em;

                    .promo-code-type {
                        margin-top: .2em;
                    }
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .block-pricing {
        border-bottom: 0;

        .block-headline {
            font-size: 2.8em;
            font-weight: 600;
        }

        .block-summary {
            .block-row-price {
                font-weight: 800;
            }
            .block-row-period {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .block-row-value {
                    margin-left: 0 !important;
                    margin-top: 1em;
                }
            }
            
            .block-row-period, .block-row-notice, .block-row-user {
                text-transform: uppercase;
                font-weight: 300;

                .block-row-label, .block-row-value {
                    display: inline-block;
                    color: ${variables.redNewPageColor};
                    font-weight: 300;
                    font-size: .7em;
                }
            }

            .block-row-user {
                justify-content: flex-start;

                .block-row-value {
                    font-weight: 400;
                    margin-left: .25em;
                }
            }
        }
    }

    .block-promo-code {
        border-bottom: 0;
        margin-bottom: 0;

        .promo-code-trigger {
            padding: 1em 1.2em;
            color: ${variables.dpc_fontDark};
            font-size: .9em;
            font-weight: 300;
            text-decoration: none;
            border: 1px solid ${variables.dpc_borderDark};

            b {
                margin-left: .2em;
                color: ${variables.dpc_fontDark};
                font-weight: 800;
            }
        }
    }

    
    @media (max-width: ${variables.mobileL}) {
        .block-pricing {
            .block-summary {
                margin-top: 0;

                .block-row {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .block-row-value {
                        font-weight: 300;
                    }
                }
            }

            .block-promo-code {
                .promo-code-trigger {
                    padding: .75em;

                    b {
                        display: block;
                        margin-top: .5em;
                        margin-left: 0;
                    }
                }

                .adept-promo-codes-verify-form {
                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .form-element {
                            width: 100%;
                        }

                        .component-button {
                            padding-left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;
