export const SHOP_PAGE_TYPE_SHOP = 'shop';
export const SHOP_PAGE_TYPE_RESERVATION = 'reservation';
export const SHOP_PAGE_TYPES = [{
    key: SHOP_PAGE_TYPE_SHOP,
    value: SHOP_PAGE_TYPE_SHOP,
    label: 'Sklep',
}, {
    key: SHOP_PAGE_TYPE_RESERVATION,
    value: SHOP_PAGE_TYPE_RESERVATION,
    label: 'Rezerwacje',
}];

export const SHOP_PAGE_LAG_TYPE_MINUTES = 'minutes';
export const SHOP_PAGE_LAG_TYPE_HOURS = 'hours';
export const SHOP_PAGE_LAG_TYPE_DAYS = 'days';
export const SHOP_PAGE_LAG_TYPES = [
    {
        key: SHOP_PAGE_LAG_TYPE_MINUTES,
        label: 'Minut',
    },
    {
        key: SHOP_PAGE_LAG_TYPE_HOURS,
        label: 'Godzin',
    },
    {
        key: SHOP_PAGE_LAG_TYPE_DAYS,
        label: 'Dni',
    },
];