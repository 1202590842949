import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    align-items: stretch;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: stretch;
        
    .headline {
        font-size: 2em;
        text-align: center;
        color: ${variables.dpc_fontDark};
        margin-bottom: 1em;
        font-weight: 900;
    }

    .adept-user-offers-vertical-list {
        .admin-paginated-list {
            .list-body {
                .admin-paginated-list-element-v2 {
                    .wrapper {
                        display: block; 

                        .block-left {
                            width: 100%;

                            .title-block {
                                width: 100%;

                                .title-content {
                                    .title {
                                        font-size: 2.5em;
                                    }
                                }
                            }
                        }
                        .block-right {
                            width: 100%;
                            padding-left: 0;

                            .details-block {
                                display: block;

                                .additionals-block {
                                    display: block;

                                    .additional {
                                        width: 100% !important;
                                        max-width: 100% !important;
                                        flex-direction: row;
                                        text-align: left;

                                        .additional-name {
                                            font-size: 1.5em;
                                            width: 70%;
                                            line-height: 1.25em;
                                            margin-bottom: 0;
                                        }

                                        .additional-value {
                                            width: 30%;
                                            font-weight: 900;
                                            font-size: 2.5em;
                                            color: #fb2231;
                                            margin-top: 0;
                                            text-align: right;
                                            padding-right: 1em;
                                            white-space: nowrap;
                                            text-transform: initial;
                                        }
                                    }
                                }

                                .controls-block {
                                    .admin-paginated-list-element-controls {
                                        .control {
                                            .component-button {
                                                .button {
                                                    margin-right: 2em;
                                                    padding: 1.5em 3.5em;

                                                    .button-content {
                                                        font-size: 1.5em;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2em 0;

        .checkout-wrapper-headline {
            font-size: 2em;
            text-align: center;
            color: ${variables.dpc_fontDark};
            margin-bottom: 1em;
            font-weight: 900;
        }

        .component-button {

        }
    }
    
    @media (max-width: ${variables.tabletS}) { 
    }
`;
