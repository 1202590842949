import {
    SHOP_PAGE_TYPES,
    SHOP_PAGE_LAG_TYPES,
} from 'Consts/shopPage';
import {
    PUBLIC_RESERVATIONS_MASSAGE_RC_NOW,
    PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI,
    PUBLIC_RESERVATIONS_ONLINE,
    PUBLIC_RESERVATIONS_MASSAGE_HOME,
    PUBLIC_RESERVATIONS_MASSAGE_RC,
} from 'Consts/routes';
import { 
    OFFER_PERIOD_TYPE_SINGLE,
    OFFER_PERIOD_TYPE_RECURRING,
    OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH, 
    OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING,
    OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY,
} from 'Consts/offers';
import { PAYMENT_TRANSACTION_TYPE_DEFAULT, PAYMENT_TRANSACTION_TYPE_SINGLE } from 'Consts/paymentTransactions';

import { dedupe, dedupeObjects } from 'Utils/array';

export const getShopTypeLabel = shopPage => {
    if(!shopPage) {
        return { found: false, label: 'Nieznany' };
    }

    const { shopType } = shopPage;
    const option = SHOP_PAGE_TYPES.find(shopPageType => shopPageType.key === shopType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getLagTypeLabel = shopPage => {
    if(!shopPage) {
        return { found: false, label: 'Nieznany' };
    }

    const { lagType } = shopPage;
    const option = SHOP_PAGE_LAG_TYPES.find(shopPageLagType => shopPageLagType.key === lagType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getShopLinks = shopPage => {
    let shopLinks = [];

    switch (shopPage.slug) {
        case 'sobieski-masaze':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje/sobieski-masaze',
                to: PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI.path,
            }];
            break;

        case 'sobieski':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje/sobieski',
                to: '/rezerwacje/sobieski',
            }, {
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje-trener/:slug',
            }];
            break;

        case 'online':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje-online',
                to: PUBLIC_RESERVATIONS_ONLINE.path,
            }, {
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje-trener/:slug',
            }];
            break;

        case 'w-domu-u-klienta':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje/w-domu-u-klienta',
                to: PUBLIC_RESERVATIONS_MASSAGE_HOME.path,
            }];
            break;

        case 'radisson-collection-grzybowska':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje/rc-masaze',
                to: PUBLIC_RESERVATIONS_MASSAGE_RC.path,
            }];
            break;

        case 'radisson-collection-grzybowska-now':
            shopLinks = [{
                label: 'Link',
                value: 'https://fitadept.app/rezerwacje/rc-masaze-now',
                to: PUBLIC_RESERVATIONS_MASSAGE_RC_NOW.path,
            }];
            break;

        default:
            shopLinks = [{
                label: 'Link do rezerwatora',
                value: 'Brak',
            }];
            break;
    }

    return shopLinks;
};

export const getUsersCountButtonVisibility = (product, frequency) => {
    let addFriendsButtonVisible = false;
    if (product && product.pricingMatrix) {
        addFriendsButtonVisible = Boolean(product.pricingMatrix
            .filter(matrix => !isNaN(matrix.usersCount))
            .filter(matrix => Number(matrix.quantity) === frequency)
            .find(matrix => matrix.usersCount > 1));
    }

    return addFriendsButtonVisible;
};

export const getOfferType = (periodType, noticePeriodDurationValue) => {
    if(periodType === OFFER_PERIOD_TYPE_SINGLE) {
        return {
            value: 'single',
            label: 'Pojedyncza',
            data: {
                features: [
                    'Płatność jednorazowa',
                ],
            },
        };
    }

    if(periodType === OFFER_PERIOD_TYPE_RECURRING && noticePeriodDurationValue) {
        return {
            value: 'metamorphosis',
            label: 'Metamorfoza',
            data: {
                features: [
                    'Płatność co miesiąc z 3-miesięcznym okresem wypowiedzenia',
                ],
            },
        };
    }

    return {
        value: 'flexible',
        label: 'Elastyczna',
        data: {
            features: [
                'Płatność co miesiąc bez okresu wypowiedzenia',
            ],
        },
    };
};

export const getTypeOptions = (offers) => {
    const typeOptions = offers.map((offer) => offer.offerTypeOption);
    return dedupeObjects(typeOptions, 'value');
};

export const getFrequencies = (offers) => {
    let valuesArray = [];

    offers
        .map((offer) => {
            offer.products.forEach(offerProduct => {
                offerProduct?.product?.pricingMatrix.forEach(pricingMatrixElem => valuesArray.push(
                    parseInt(pricingMatrixElem.quantity)
                ));
            });
        });

    valuesArray = dedupe(valuesArray);
    valuesArray.sort((a,b) => a-b);
    return valuesArray;
};

export const getResults = (offers, durationValue) => {
    if(offers.length === 0) {
        return null;
    }

    const offer = offers[0];
    let offerProduct = null;
    let product = null;

    offer.products.forEach(offerProd => {
        if(offerProd.product.durationValue === durationValue) {
            offerProduct = offerProd;
            product = offerProduct.product;
        }
    });

    if(!offerProduct || !product || !Array.isArray(product.pricingMatrix)) {
        throw new Error(null);
    }

    return {
        offer,
        offerProduct,
        product,
    };
};

export const getAvailablePaymentTypes = (offer) => {
    let availablePaymentTypes = [];

    if(offer?.firstPaymentMethodType) {
        switch (offer?.firstPaymentMethodType) {
            case OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH:
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
                break;
            case OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING:
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
                break;
            case OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY:
                availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE];
                break;
        }
    } else {
        if (offer?.periodType === OFFER_PERIOD_TYPE_SINGLE) {
            availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT];
        } else {
            availablePaymentTypes = [PAYMENT_TRANSACTION_TYPE_DEFAULT];
        }
    }

    return availablePaymentTypes;
};

export const getPricing = (product, frequencyMonth, usersCount) => {
    let userProductId = null;

    const pricing = product?.pricingMatrix?.find(pricingMatrixElem => 
        parseInt(pricingMatrixElem.quantity) === frequencyMonth 
        && (
            pricingMatrixElem.usersCount 
                ? parseInt(pricingMatrixElem.usersCount) === usersCount 
                : true
        )
    ) || null;        

    let totalPrice = 0;
    if(product && frequencyMonth) {
        totalPrice = pricing
            ? pricing.valueGross * frequencyMonth
            : 0;
    }

    let discountPricing = null;
    let totalDiscountPrice = 0;
    if(product?.userProduct) {
        userProductId = product?.userProduct?.id;
        discountPricing = product?.userProduct?.pricingMatrix?.find(pricingMatrixElem => parseInt(pricingMatrixElem.quantity) === frequencyMonth && (pricingMatrixElem.usersCount ? parseInt(pricingMatrixElem.usersCount) === usersCount : true)) || null;
        totalDiscountPrice = 0;
        if(product && frequencyMonth) {
            totalDiscountPrice = discountPricing
                ? discountPricing.valueGross * frequencyMonth
                : 0;
        }
    }

    return { 
        pricing, 
        totalPrice, 
        discountPricing, 
        totalDiscountPrice,
        userProductId,
    };
};