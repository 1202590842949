import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { dedupe } from 'Utils/array';

import StyledComponent from 'Components/core/StyledComponent';

export default class PublicShopOfferCreator extends Component {
    static propTypes = {
        offers: PropTypes.array.isRequired,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        onSelectDurationValue: PropTypes.func.isRequired,
        selectedDurationValue: PropTypes.number,
    };
    static defaultProps = {
        title: 'Skonfiguruj swój pakiet',
        selectTitle: 'Wybierz pakiet',
        selectedDurationValue: null,
    };

    getDurationValues = (offers) => {
        const valuesArray = offers
            .map((offer)  => {
                const durationValues = offer.products.map(offerProduct => {
                    return offerProduct?.product?.durationValue || 0;
                });

                return durationValues;
            });

        let values = [];
        valuesArray.forEach(valuesArrayElem => {
            values = dedupe([
                ...values,
                ...valuesArrayElem,
            ]);
        });

        values.sort((a,b) => a-b);
        return values;
    }

    render() {
        const { 
            offers,
            title,
            subtitle,
            onSelectDurationValue,
            selectedDurationValue,
        } = this.props;

        const durationValues = this.getDurationValues(offers);

        return (
            <StyledComponent
                className="public-shop-offer-creator"
                styles={require('./styles')}
            >
                <div className="header">
                    <h2 className="title">
                        {title}
                    </h2>
                    <h3 className="subtitle">
                        {subtitle}
                    </h3>
                </div>
                <div className="options">
                    {durationValues
                        .map(durationValue => (
                            <div 
                                key={durationValue}
                                className={classnames(
                                    'option',
                                    { active: selectedDurationValue && selectedDurationValue === durationValue }
                                )}
                                onClick={() => onSelectDurationValue(durationValue)}
                            >
                                <span className="option-bg" />
                                <div className="option-content">
                                    <h3 className="option-title">
                                        {durationValue} Minut
                                    </h3>
                                </div>
                            </div>
                        ))}
                </div>
            </StyledComponent>
        );
    }
}