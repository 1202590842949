import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translateDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';
import { OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class PublicShopOfferPricing extends Component {
    static propTypes = {
        productName: PropTypes.string,
        productNameMany: PropTypes.string,
        onComplete: PropTypes.func.isRequired,
        frequency: PropTypes.number,
        totalPrice: PropTypes.number,
        unitPrice: PropTypes.string,
        totalDiscountPrice: PropTypes.number,
        unitDiscountPrice: PropTypes.string,
        step: PropTypes.string,
        offer: PropTypes.object,
        selectedUsersCount: PropTypes.number,
    };
    static defaultProps = {
        productName: 'trening',
        productNameMany: 'treningów',
    };

    render() {
        const { 
            frequency, 
            onComplete, 
            totalPrice, 
            totalDiscountPrice, 
            unitPrice, 
            unitDiscountPrice, 
            offer, 
            productName, 
            productNameMany,
            selectedUsersCount,
        } = this.props;
        
        return (
            <StyledComponent
                className="public-shop-offer-pricing"
                styles={require('./styles')}
            >
                <div className="block block-summary">
                    {Boolean(unitPrice) && (
                        <div className="block-row block-row-training-price">
                            <div className="block-row-label">
                                Cena za {productName}: 
                            </div>
                            {unitDiscountPrice && (
                                <div className="block-row-value">
                                    <span className="value-origin">{formatPrice(unitPrice)}</span>
                                    <span className="value-discount">{formatPrice(unitDiscountPrice)}</span>
                                </div>
                            )}
                            {!unitDiscountPrice && (
                                <div className="block-row-value">
                                    {formatPrice(unitPrice)}
                                </div>
                            )}
                        </div>
                    )}
                    {Boolean(frequency) && (
                        <div className="block-row block-row-trainings">
                            <div className="block-row-label">
                                Ilość {productNameMany}: 
                            </div>
                            <div className="block-row-value">
                                {frequency} {offer?.periodType === OFFER_PERIOD_TYPE_RECURRING ? 'miesięcznie' : ''}
                            </div>
                        </div>
                    )}                  
                    {offer?.periodType && (
                        <div className="block-row block-row-period">
                            <div className="block-row-label">
                                Płatność:
                            </div>
                            <div className="block-row-value">
                                {offer?.periodType === OFFER_PERIOD_TYPE_RECURRING 
                                    ? ('Co miesiąc') 
                                    : ('Jednorazowa')
                                }
                            </div>
                        </div>
                    )}
                    {offer?.periodType === OFFER_PERIOD_TYPE_RECURRING && (
                        <div className="block-row block-row-notice">
                            <div className="block-row-label">
                                Okres wypowiedzenia:
                            </div>
                            <div className="block-row-value">
                                {offer?.noticePeriodDurationType && offer?.noticePeriodDurationValue 
                                    ? (
                                        <span>
                                            {offer?.noticePeriodDurationValue} {translateDate(offer?.noticePeriodDurationType, offer?.noticePeriodDurationValue)}
                                        </span>
                                    ) 
                                    : (
                                        <span>
                                            Bez zobowiązań
                                        </span>
                                    )}
                            </div>
                        </div>
                    )}
                    {selectedUsersCount && selectedUsersCount > 1 && (
                        <div className="block-row block-row-users-count">
                            <div className="block-row-label">
                                Ilość trenujących osób:
                            </div>
                            <div className="block-row-value">
                                <span>
                                    {selectedUsersCount}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="block block-footer">
                    {Boolean(totalDiscountPrice && totalPrice) && (
                        <div className="block-row block-row-price">
                            <div className="block-row-label">
                                Cena specjalna:
                            </div>
                            <div className="block-row-value">
                                <span className="value-origin">{formatPrice(totalPrice)}</span>
                                <span className="value-discount">{formatPrice(totalDiscountPrice)}</span>
                            </div>
                        </div>
                    )}
                    {Boolean(!totalDiscountPrice && totalPrice) && (
                        <div className="block-row block-row-price">
                            <div className="block-row-label">
                                Cena pakietu:
                            </div>
                            <div className="block-row-value">
                                {formatPrice(totalPrice)}
                            </div>
                        </div>
                    )}
                    <Button
                        style="newPage"
                        size="large"
                        onClick={onComplete}
                        layout="fullWidth"
                    >
                        Dalej <FontAwesomeIcon icon={['fa', 'chevron-right']} />
                    </Button>
                </div>
            </StyledComponent>
        );
    }
}