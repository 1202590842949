import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const verify = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.VERIFY,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_PROMO_CODE_VERIFY, params),
        params,
        requestParams: params,
    }));
};
