import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT } from 'Consts/paymentTransactions';

import StyledComponent from 'Components/core/StyledComponent';
import PaymentMethodRecurring from 'Components/public/shop/PaymentMethodRecurring';

export default class PublicShopPaymentTypes extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,

        userEmail: PropTypes.string,
        availablePaymentTypes: PropTypes.arrayOf(
            PropTypes.oneOf(['single', 'default']),
        ).isRequired,
        paymentType: PropTypes.oneOf(['single', 'default']),
        paymentMethod: PropTypes.object,
        onSelectPaymentType: PropTypes.func.isRequired,
        onSelectPaymentMethod: PropTypes.func.isRequired,
    };
    static defaultProps = {
        userEmail: null,
        paymentType: null,
        paymentMethod: null,
    };
    state = {};

    componentDidMount = () => {
        //Set initial payment type
        this.setInitialPaymentType();
    }

    componentDidUpdate = prevProps => {
        const { availablePaymentTypes } = this.props;

        //Set initial payment type
        if(JSON.stringify(availablePaymentTypes) !== JSON.stringify(prevProps.availablePaymentTypes)) {
            this.setInitialPaymentType();
        }
    }

    setInitialPaymentType = () => {
        const { availablePaymentTypes, onSelectPaymentType } = this.props;

        //If there is only on payment type available, select first one
        if(availablePaymentTypes.length === 1) {
            onSelectPaymentType(availablePaymentTypes[0]);
        }
    }

    render() {
        const { location, history, onSelectPaymentType, onSelectPaymentMethod, paymentType, paymentMethod, availablePaymentTypes, userEmail } = this.props;

        return (
            <StyledComponent
                className="public-shop-payment-types"
                styles={require('./styles')}
            >
                {!paymentType && (
                    <div className="type-select-container">
                        <h2 className="type-select-title">
                            Wybierz metodę płatności
                        </h2>
                        <div className="type-select-options">
                            {availablePaymentTypes.includes(PAYMENT_TRANSACTION_TYPE_SINGLE) && (
                                <div 
                                    className="type-select-option"
                                    onClick={() => onSelectPaymentType(PAYMENT_TRANSACTION_TYPE_SINGLE)}
                                >
                                    Szybki przelew
                                </div>
                            )}
                            {availablePaymentTypes.includes(PAYMENT_TRANSACTION_TYPE_DEFAULT) && (
                                <div 
                                    className="type-select-option"
                                    onClick={() => onSelectPaymentType(PAYMENT_TRANSACTION_TYPE_DEFAULT)}
                                >
                                    Karta płatnicza
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {availablePaymentTypes.length > 1 && paymentType && (
                    <div 
                        className="type-select-back"
                        onClick={() => onSelectPaymentType(null)}
                    >
                        Zmień metodę płatności
                    </div>
                )}

                {paymentType === PAYMENT_TRANSACTION_TYPE_DEFAULT && (
                    <div className="type-container type-card-container">
                        <h3 className="type-container-title">
                            Płatność kartą płatniczą
                        </h3>
                        <PaymentMethodRecurring 
                            location={location}
                            history={history}
                            paymentMethod={paymentMethod}
                            onSelectPaymentMethod={onSelectPaymentMethod}
                            userEmail={userEmail}
                        />
                    </div>
                )}

                {paymentType === PAYMENT_TRANSACTION_TYPE_SINGLE && (
                    <div className="type-container type-single-container">
                        <h3 className="type-container-title">
                            Płatność szybkim przelewem
                        </h3>
                        <img
                            src={require('Theme/images/payu-logo-lime.png')}
                            className="payu-logo"
                        />
                    </div>
                )}
               
            </StyledComponent>
        );
    }
}