import { reduxRequest as request } from 'Services/Api';
import { getProvider } from 'Services/Cookies';

import { COOKIE_UTM_ORDER_DATA } from 'Consts/cookies';

import getStore from 'Redux';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const create = dispatch => (params = {}) => {
    const utmData = getStore().getState().user.utm;
    getProvider().set(COOKIE_UTM_ORDER_DATA, JSON.stringify(utmData));

    const endpoint = params.price > 0
        ? endpoints.ENDPOINT_OLD_FORM_ORDERS
        : endpoints.ENDPOINT_OLD_FORM_ORDERS_PROMO;

    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        apiUrl: process.env.OLD_API_URL,
        path: endpoint,
        params,
        requestParams: {
            ...params,
            ...utmData,
        },
    }));
};

export const createByVoucher = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_BY_VOUCHER,
        method: 'POST',
        path: endpoints.ENDPOINT_PUBLIC_CREATE_ORDER_BY_VOUCHER,
        params,
        requestParams: {
            name: params.name,
            surname: params.surname,
            phone: params.phone,
            email: params.email,
            couponCode: params.couponCode,
            consentsMarketingPhone: params.consentsMarketingPhone,
            consentsMarketingEmail: params.consentsMarketingEmail,
            consentsMarketingTerms: params.consentsMarketingTerms,
        },
    }));
};
