import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import UsersCount from 'Components/public/shop/UsersCount';

export default class PublicModalUsersCount extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        selectedProduct: PropTypes.object,
        onSelectUsersCount: PropTypes.func.isRequired,
        selectedUsersCount: PropTypes.number,
        selectedFrequencyMonth: PropTypes.number,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: null,
    };

    render() {
        const { location, history, isOpen, onClose, selectedProduct, onSelectUsersCount, selectedUsersCount, selectedFrequencyMonth } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="adept-modal-users-count" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title='Wybierz ilość trenujących' />
                        <UsersCount
                            location={location}
                            history={history}
                            onClose={onClose}
                            selectedProduct={selectedProduct}
                            title='Wybierz ilość trenujących'
                            onSelectUsersCount={onSelectUsersCount}
                            selectedUsersCount={selectedUsersCount}
                            selectedFrequencyMonth={selectedFrequencyMonth}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
