import { connect } from 'react-redux';
import Component from './component';

import { list as listUserPaymentMethods } from 'Redux/modules/adept/userPaymentMethods/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        userPaymentMethods: state.adeptUserPaymentMethods.list,
    }),
    dispatch => ({
        actions: {
            listUserPaymentMethods: dispatch(listUserPaymentMethods),
        },
    }),
)(Component);
