import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 70vw;

    .elements-wrapper {
        width: 100%;
        background: rgb(37, 37, 37);
        margin-bottom: 1em;
        text-align: left;

        .element-header {
            margin-top: 2em;
            margin-left: 2em;
            color: white;
            font-size: 1.3em;
            font-weight: 700;
        }

        .element-price {
            display: flex;
            height: 2em;
            line-height: 2em;
            margin-top: 2em;

            .element-price-header {
                color: white;
                width: 80%;
                padding-left: 3em;
            }

            .element-price-gross {
                color: rgb(251, 34, 49);
                font-weight: 900;
                font-size: 1.5em;
                width: 15%;
                text-align: right;
                margin-right: 2em;
            }
        }

        .element-products {
            margin-left: 3em;
            color: white;

            .product {
                display: flex;
                height: 2em;
                line-height: 2em;

                .product-name {
                    width: 80%;
                }

                .product-quantity {
                    width: 15%;
                    text-align: right;
                    color: rgb(251, 34, 49);
                    font-weight: 900;
                    font-size: 1.5em;
                    margin-right: 2em;
                }
            }
        }

        .element-controls {
            float: right;
            margin-right: 3em;
            margin-top: 2em;
            margin-bottom: 1em;
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .elements-wrapper {
            .element-price {
                width: 100%;
                display: inline-block;

                .element-price-header {
                    width: 100%;
                    padding: 0;
                    text-align: center;
                }

                .element-price-gross {
                    width: 100%;
                    text-align: center;
                }
            }

            .element-products {
                margin-left: 0;

                .product {
                    display: inline-block;
                    width: 100%;

                    .product-name {
                        width: 100%;
                        text-align: center;
                    }

                    .product-quantity {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .element-controls {
                width: 90%;
                margin-left: 5%;
                float: unset;

                .component-button {
                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }
`;
