import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class PublicShopUsersCount extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onClose: PropTypes.func,
        selectedProduct:  PropTypes.object,
        selectedUsersCount: PropTypes.number,
        onSelectUsersCount: PropTypes.func.isRequired,
        selectedFrequencyMonth: PropTypes.number,
    };
    
    static defaultProps = {
        data: null,
    };

    state = {};

    componentDidMount = () => {
        const { onSelectUsersCount, selectedProduct } = this.props;
        const availableUserCounts = selectedProduct?.pricingMatrix.map(price => parseInt(price.usersCount)) || [];
        if (availableUserCounts.lenght > 0) {
            onSelectUsersCount(
                availableUserCounts[0]
            );
        }
    }

    render() {
        const { selectedProduct, selectedUsersCount, onSelectUsersCount, selectedFrequencyMonth } = this.props;

        const availableUserCounts = selectedProduct?.pricingMatrix
            .filter(matrix => Number(matrix.quantity) === selectedFrequencyMonth)
            .map(price => parseInt(price.usersCount));

        return (
            <StyledComponent
                className="public-shop-users-count"
                styles={require('./styles')}
            >
                <div className="options">
                    {availableUserCounts && availableUserCounts.map(usersCount => (
                        <div 
                            key={usersCount}
                            className={classnames(
                                'option',
                                { active: selectedUsersCount === usersCount }
                            )}
                            onClick={() => onSelectUsersCount(usersCount)}
                        >
                            <span className="option-bg" />
                            <div className="option-content">
                                <h3 className="option-title">
                                    {usersCount}
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </StyledComponent>
        );
    }
}