import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class PublicShopOfferTrainingsNumber extends Component {
    static propTypes = {
        title: PropTypes.string,
        frequencies: PropTypes.array,
        selectedFrequencyMonth: PropTypes.number,
        onSelectFrequencyMonth: PropTypes.func.isRequired,
    };
    static defaultProps = {
        title: 'Wybierz ilość treningów w miesiącu',
        frequencies: [],
        selectedFrequencyMonth: null,
    };

    componentDidMount = () => {
        const { frequencies, onSelectFrequencyMonth } = this.props;
        if (frequencies.length === 1) {
            onSelectFrequencyMonth(frequencies[0]);
        } 
    }

    render() {
        const { frequencies, title, selectedFrequencyMonth, onSelectFrequencyMonth } = this.props;

        return (
            <StyledComponent
                className="public-shop-offer-trainings-number"
                styles={require('./styles')}
            >
                <div className="header">
                    <h3 className="title">
                        {title}
                    </h3>
                </div>
                <div className="options">
                    {frequencies.map(frequencyMonth => (
                        <div 
                            key={frequencyMonth}
                            className={classnames(
                                'option',
                                {   active: selectedFrequencyMonth === frequencyMonth }
                            )}
                            onClick={() => onSelectFrequencyMonth(frequencyMonth)}
                        >
                            <span className="option-bg" />
                            <div className="option-content">
                                <h3 className="option-title">
                                    {frequencyMonth}
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </StyledComponent>
        );
    }
}