import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    margin-bottom: 1em;

    .view-cols {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .view-col-info {
            width: 50%;
            padding-right: 5em;
            padding-top: 2em;
            text-align: left;

            .info-headline {
                font-size: 2.8em;
                color: ${variables.dpc_fontDark};
                font-weight: 600;
                line-height: 90%;
            }

            .info-subheadline {
                font-size: 1.3em;
                color: ${variables.dpc_fontDark};
                font-weight: 800;
                margin: .5em 0;
                text-transform: uppercase;
            }

            .change-view-link {
                color: #ff0000;
                font-size: 1em;
                font-weight: 600;
                margin-top: .9em;
                display: block;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .view-col-form {
            width: 50%;

            form {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding-bottom: 2em;

                .form-element {
                    width: 48%;
                }

                .regulations-container {
                    position: relative;
                    margin-top: 1em;
                    width: 100%;

                    .form-element {
                        width: 100%;
                    }
                }

                .disclaimer {
                    display: inline-block;
                    width: 100%;
                    height: 3em;
                    color: #666;
                    font-size: .7em;
                    line-height: 1.5em;
                    white-space: pre-wrap;
                    overflow: hidden;

                    &.expandDisclaimer {
                        height: auto;
                    }
                }

                > .component-button {
                    width: 100%;
                }
            }

            .section-disclaimer {
                font-size: .9em;
                color: ${variables.dpc_fontDark};
                text-align: center;
                font-weight: 400;

                .link {
                    font-weight: 300;
                    color: ${variables.dpc_fontDark};
                    font-size: 1.1em;
                }
            }
        }
    }

    .confirm-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
        margin-top: 3em;

        .view-cols {
            width: 100%;
            justify-content: center;

            .view-col-info {
                text-align: left;
                width: 100%;
                padding: 0;

                .component-button {
                    margin-top: 1em;
                    display: flex;
                    justify-content: flex-start;
                }
            }
        }
    }

    .register-wrapper {
        .view-cols {
            display: flex;
            flex-direction: column;

            .view-col-info {
                width: 100%;
                padding-right: 0;
            }
            .view-col-form {
                margin-top: 2em;
                width: 100%;
            }
        }
    }

    .login-wrapper {
        .view-cols {
            display: flex;
            flex-direction: column;

            .view-col-info {
                width: 100%;
                padding-right: 0;
            }
            .view-col-form {
                margin-top: 3em;
                width: 100%;
            }
        }
    }
    @media (max-width: ${variables.mobileL}) {}
`;
