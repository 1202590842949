import { connect } from 'react-redux';
import Component from './component';

import { verify } from 'Redux/modules/adept/promoCodes/actions';

export default connect(
    state => ({
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            verify: dispatch(verify),
        },
    }),
)(Component);