import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .form-error-message-component {
                width: 100%;
                margin: .5em 0;
            }

            .form-element {
                width: 70%;
                margin-top: 0;

                .input-external-wrapper {
                    margin-bottom: 0;

                    .form-error-message-component {
                        position: absolute;
                        top: 100%;
                    }
                }
            }
            .component-button {
                width: 30%;
                padding-left: 1em;
                margin-bottom: .2em;

                button {
                    height: 3.4em;
                }
            }
        }
    `;
