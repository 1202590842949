import { connect } from 'react-redux';

import { register } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({
        utmData: state.user.utm,
    }),
    dispatch => ({
        actions: {
            register: dispatch(register),
        },
    })
)(Component);

