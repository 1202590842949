import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PRODUCT_CATEGORY_SHOP_MAP } from 'Consts/products';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Spinner from 'Components/layout/Spinner';
import UserForm from 'Components/public/shop/UserForm';
import OfferError from 'Components/public/shop/OfferError';

export default class PublicShopMigrationWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        children: PropTypes.any,
        productCategory: PropTypes.string.isRequired,

        // Redux props
        authToken: PropTypes.string,
        userOffer: PropTypes.object,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        children: null,
        authToken: null,
        userOffer: null,
    };
    state = {
        isValidated: false,
        validationStep: null,
        userOffer: null,
    };

    componentDidMount = () => {
        const { location, authToken, actions } = this.props;
        const queryObject = parseQueryToObject(location.search);

        if(queryObject.userOfferId) {
            if(!authToken) {
                this.setState({ validationStep: 'login' });
            }
            
            if(authToken) {
                actions.single({ id: queryObject.userOfferId })
                    .catch((error) => console.log('error'));
            }
        } else {
            this.setState({ isValidated: true });
        }
    }

    componentDidUpdate = (prevProps) => {
        const { location, authToken, userOffer, productCategory, actions } = this.props;
        const { validationStep, isValidated } = this.state;
        const queryObject = parseQueryToObject(location.search);

        if(queryObject.userOfferId) {
            if(authToken !== prevProps.authToken) {
                actions.single({ id: queryObject.userOfferId })
                    .catch((error) => console.log('error'));
            }

            if(userOffer && userOffer.isLoaded && userOffer.data && !isValidated && validationStep !== 'userOfferError') {
                const productCategoryMapElem = PRODUCT_CATEGORY_SHOP_MAP.find(productCategoryShopElem => productCategoryShopElem.shopSlug === productCategory);
                if(userOffer.data?.productCategory?.slug !== productCategoryMapElem?.productCategorySlug) {
                    this.setState({ validationStep: 'userOfferError' });
                } else {
                    this.setState({ 
                        isValidated: true,
                        validationStep: null,
                        userOffer, 
                    });
                }
            }
            
            if(userOffer && userOffer.isLoaded && !userOffer.data && validationStep !== 'userOfferError') {
                this.setState({ 
                    validationStep: 'userOfferError',
                });
            }
        }
    }

    render() {
        const { children, location, history } = this.props;
        const { isValidated, validationStep, userOffer } = this.state;
        const queryObject = parseQueryToObject(location.search);

        if(!queryObject.userOfferId || isValidated) {
            return children({ userOffer });
        }

        let validationStepMarkup = (<Spinner />);
        switch (validationStep) {
            case 'login':
                validationStepMarkup = (
                    <LayoutContainer size="xs">
                        <UserForm 
                            location={location}
                            history={history}
                            initialFormType="login"
                            availableFormTypes={['login']}
                        />
                    </LayoutContainer>
                );
                break;
            case 'userOfferError':
                validationStepMarkup = (
                    <LayoutContainer>
                        <OfferError />
                    </LayoutContainer>
                );
                break;
            default:
                break;
        }
        
        return (
            <StyledComponent
                className="public-shop-migration-wrapper"
                styles={require('./styles')}
            >
                {validationStepMarkup}
            </StyledComponent>
        );
    }
}