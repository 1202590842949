import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Calendar from 'react-calendar';

import { PUBLIC_RESERVATIONS_GYM_SELECT_FORM } from 'Consts/components';
import { API_RESOURCE_EVENTS_FREE_SLOTS_BY_SCORE } from 'Consts/apiResources';
import { TIME_API_FORMAT } from 'Consts/date';

import { translate, checkLocation } from 'Utils/language';
import { toApiFormat, toAppFormat } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import Spinner from 'Components/layout/Spinner';

moment.locale('pl');

export default class PublicReservationsSlotSelectForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        actions: PropTypes.shape({
            freeSlotsByScore: PropTypes.func.isRequired,
            freeTrainers: PropTypes.func.isRequired,
        }).isRequired,
        leadId: PropTypes.string,
        selectedLocation: PropTypes.object.isRequired,
        selectedProduct: PropTypes.object.isRequired,
        currentLanguage: PropTypes.string,
        previewMode: PropTypes.bool,
        lagValue: PropTypes.number,
        lagType: PropTypes.string,
    };

    static defaultProps = {
        previewMode: false,
        lagValue: 2,
        lagType: 'hours',
    };

    state = {
        calendar: [],
        slots: [],
        isLoaded: false,
        date: moment().toDate(),
        leadId: null,
        activeSlot: {
            slot: {
                time: null,
                trainerId: null,
            },
        },
        freeTrainers: null,
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { selectedLocation, selectedProduct } = this.props;
        const { activeSlot } = this.state;
        
        if (JSON.stringify(prevProps.selectedLocation) !== JSON.stringify(selectedLocation)) {
            this.loadFreeSlots();
        }

        if (activeSlot && activeSlot.slot && activeSlot.slot.time && selectedProduct && JSON.stringify(activeSlot) !== JSON.stringify(prevState.activeSlot)) {
            this.loadFreeTrainers();
        }
    }

    componentDidMount = () => {
        this.loadFreeSlots();
    }

    loadFreeSlots = () => {
        const { actions, selectedLocation, selectedProduct, leadId, lagValue, lagType } = this.props;
        const { date } = this.state;

        this.setState({
            slots: [],
            isLoaded: false,
        }, () => {
            let startAt = null;

            if (moment(date).isSame(moment(), 'day')) {
                startAt = moment(date)
                    .clone()
                    .add(1, 'hours')
                    .add(lagValue, lagType)
                    .startOf('hour');
            }

            if (!startAt && moment(date).isSame(moment().add(1, 'days'), 'day') && moment().isAfter(moment().set('hour', 19))) {
                startAt = moment(date)
                    .clone()
                    .set('hour', 9)
                    .startOf('hour'); 
            }

            if (!startAt) {
                startAt = moment(date)  
                    .clone()
                    .startOf('day');
            }

            actions.freeSlotsByScore({
                locationId: selectedLocation.id,
                productId: selectedProduct.id,
                startAt: toApiFormat(startAt, 'datetime', true),
                leadId,
                endAt: toApiFormat(moment(date).endOf('day')),
                timeInterval: 15,
            })
                .then(response => {
                    const result = response.payload[API_RESOURCE_EVENTS_FREE_SLOTS_BY_SCORE];
                    this.setState({
                        isLoaded: true,
                        slots: result,
                    });
                })
                .catch(error => {
                    this.setState({
                        isLoaded: true,
                        slots: [],
                    });
                });
        });
    }

    loadFreeTrainers = () => {
        const { actions, selectedProduct, selectedLocation } = this.props;
        const { activeSlot } = this.state;

        actions.freeTrainers({ 
            slug: selectedLocation.slug, 
            startAt: activeSlot.slot.time, 
            productId: selectedProduct.id 
        }).then(response => {
            this.setState({ freeTrainers: response.payload.freeTrainers })
        });
    }

    onSubmit = () => {
        const { onSubmit } = this.props;
        const { activeSlot } = this.state;

        return onSubmit({ slot: activeSlot });
    }

    onChangeDate = date => {
        this.setState(prevState => ({
            date,
            activeSlot: {
                ...prevState.activeSlot,
                slot: {
                    trainerId: null,
                    time: null,
                },
            },
        }), () => {
            this.loadFreeSlots();
        });
    }

    onSelectSlot = slot => {
        this.setState(prevState => ({
            activeSlot: {
                ...prevState.activeSlot,
                slot,
            },
        }));
    }

    renderSlotList = (slots, time) => {
        const { activeSlot } = this.state;
        const { currentLanguage, location } = this.props;

        if (slots.length) {
            slots = slots.filter(slot => slot.trainerId != null);
            if (slots.length) {
                switch (time) {
                    case 'morning':
                        slots = slots.filter(slot => moment(slot.time).isBefore(moment(slot.time).hour(10)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: activeSlot && activeSlot.slot && activeSlot.slot.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_renderSlotList_none_label`, currentLanguage, location)}
                            </div>
                        );
                    case 'midday':
                        slots = slots.filter(slot => moment(slot.time).isAfter(moment(slot.time).hour(9)) && moment(slot.time).isBefore(moment(slot.time).hour(16)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: activeSlot && activeSlot.slot && activeSlot.slot.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_renderSlotList_none_label`, currentLanguage, location)}
                            </div>
                        );
                    case 'evening':
                        slots = slots.filter(slot => moment(slot.time).isAfter(moment(slot.time).hour(15)) && moment(slot.time).isBefore(moment(slot.time).hour(22)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: activeSlot && activeSlot.slot && activeSlot.slot.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_renderSlotList_none_label`, currentLanguage, location)}
                            </div>
                        );
                }
            }
        }

        return (
            <div
                className={classnames({
                    slot: true,
                    disabled: true,
                })}
            >
                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_renderSlotList_none_label`, currentLanguage, location)}
            </div>
        );
    }

    render() {
        const { currentLanguage, location, selectedProduct, selectedLocation, previewMode } = this.props;
        const { date, activeSlot, slots, isLoaded, freeTrainers } = this.state;

        return (
            <StyledComponent
                className="component-public-reservations-slot-select-form"
                styles={require('./styles')}
            >
                <div className="body">
                    <div className="column column-time">
                        {!previewMode && (
                            <>
                                <div className="header-info">
                                    {selectedLocation && (
                                        <div className="location-headline">
                                            {selectedLocation.name}
                                        </div>
                                    )}
                                    {selectedProduct && (
                                        <h3 className="product-headline">
                                            {selectedProduct.name}
                                        </h3>
                                    )}
                                </div>
                                <h1 className="headline">
                                    {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_columnTime_headline`, currentLanguage, location)}
                                </h1>
                                <h2 className="subheadline">
                                    <span className="date">
                                        {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_footer_columnDate_label`, currentLanguage, location)}
                                        : {moment(date).format('DD.MM.YYYY')}
                                    </span>
                                    <span className="price">
                                        <span className="price-label">
                                            {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_columnPrice_label`, currentLanguage, location)}:
                                        </span>
                                        <span className="price-current">
                                            {formatPrice(selectedProduct.priceGross)}
                                        </span>
                                    </span>
                                </h2>
                            </>
                        )}
                        <div className="time-select">
                            {!isLoaded && (<Spinner className="no-bg" />)}
                            {isLoaded && (
                                <div className="slots-container">
                                    <div className="slots-category slots-category-morning">
                                        <h3 className="slot-category-label">
                                            {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_timeSelect_slotsContainer_categoryMorning_label`, currentLanguage, location)}
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'morning')}
                                        </div>
                                    </div>
                                    <div className="slots-category slots-category-midday">
                                        <h3 className="slot-category-label">
                                            {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_timeSelect_slotsContainer_categoryMidday_label`, currentLanguage, location)}
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'midday')}
                                        </div>
                                    </div>
                                    <div className="slots-category slots-category-evening">
                                        <h3 className="slot-category-label">
                                            {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_timeSelect_slotsContainer_categoryEvening_label`, currentLanguage, location)}
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'evening')}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="column column-date">
                        {!previewMode && (
                            <h1 className="headline">
                                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_columnDate_headline`, currentLanguage, location)}
                            </h1>
                        )}
                        <Calendar
                            onChange={this.onChangeDate}
                            value={date}
                            locale={checkLocation(location) ? currentLanguage : 'pl'}
                            maxDate={moment().add(10, 'days')
                                .toDate()}
                            minDate={moment().toDate()}
                            maxDetail="month"
                            minDetail="month"
                            activeStartDate={moment().toDate()}
                        />
                    </div>
                </div>
                {!previewMode && (
                    <>
                        <p className="disclaimer">
                            {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_disclaimer`, currentLanguage, location)}
                        </p>
                        {freeTrainers && freeTrainers > 0 && (
                            <p className="free-trainers">
                                {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_freeTrainers`, currentLanguage, location) + freeTrainers}
                            </p>
                        )}
                        <div className="footer">
                            <div className="column column-date">
                                <h3 className="column-label">
                                    {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_footer_columnDate_label`, currentLanguage, location)}
                                </h3>
                                <span className="column-value">
                                    {date
                                        ? moment(date).format('DD.MM.YYYY')
                                        : '---'
                                    }
                                </span>
                            </div>
                            <div className="column column-time">
                                <h3 className="column-label">
                                    {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_footer_columnTime_label`, currentLanguage, location)}
                                </h3>
                                <span className="column-value">
                                    {activeSlot && activeSlot.slot && activeSlot.slot.time
                                        ? moment.utc(activeSlot.slot.time).local().format('HH:mm')
                                        : '---'
                                    }
                                </span>
                            </div>
                            <div className="column column-submit">
                                <Button
                                    size="extraLarge"
                                    style="newPage"
                                    onClick={this.onSubmit}
                                    disabled={Boolean(activeSlot && activeSlot.slot && !activeSlot.slot.time)}
                                >
                                    {translate(`${PUBLIC_RESERVATIONS_GYM_SELECT_FORM}_footer_columnSubmit_button`, currentLanguage, location)}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </StyledComponent>
        );
    }
}
