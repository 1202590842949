import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${variables.dpc_backgroundWhite};
    border: 1px solid ${variables.redNewPageColor};
    border-radius: .2em;
    padding: 2em;
    margin-top: 2em;
    width: 100%;

    .block {
        &.block-summary {
            width: 100%;

            .block-row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 1em;
                margin-bottom: .5em;
                padding-bottom: .5em;

                .block-row-label {
                    color: black;
                    font-weight: 300;
                }
                .block-row-value {
                    color: ${variables.redNewPageColor};
                    margin-left: .5em;
                    font-weight: 600;

                    .value-origin {
                        text-decoration: line-through;
                        color: #666;
                        padding-right: .5em;
                    }
                }
            }
        }

        &.block-footer {
            .block-row {
                margin-bottom: .5em;
                padding-bottom: .5em;
                font-size: 1.5em;
                font-weight: 600;

                .block-row-label {
                    color: black;
                    font-weight: 300;
                    font-size: .8em;
                }
                .block-row-value {
                    color: ${variables.dpc_fontDark};
                    font-weight: 900;
                    color: #D83143;
                    font-size: 1.7em;
                }

                &.block-row-price {
                    font-size: 1.5em;
                    font-weight: 600;

                    .block-row-value {
                        color: ${variables.redNewPageColor};
                        min-width: 8em;

                        .value-origin {
                            display: block;
                            text-decoration: line-through;
                            color: #666;
                            padding-right: .5em;
                            font-size: 70%;
                            margin-top: .5em;
                            font-weight: 300;
                        }
                        .value-discount {
                            display: block;
                        }
                    }
                }
            }

            .component-button {
                .button {
                    .button-content {
                        display: flex;

                        svg {
                            margin-left: .5em;
                        }
                    }
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    @media (max-width: ${variables.tabletL}) {}
    @media (max-width: ${variables.mobileL}) {
        flex-wrap: wrap;

        .block {
            &.block-footer {
                width: 100%;
                margin-top: 2em;

                .component-button {
                    width: 100%;

                    .button {
                        width: 100%;
                    }
                }
            }
        }
    }
`;
