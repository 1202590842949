import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class PublicShopOfferPackage extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        typeOptions: PropTypes.array.isRequired,
        selectedOfferType: PropTypes.string,
        onSelectOfferType: PropTypes.func.isRequired,
    };
    static defaultProps = {};

    render() {
        const { title, subtitle, typeOptions, selectedOfferType, onSelectOfferType } = this.props;

        return (
            <StyledComponent
                className="public-shop-offer-package"
                styles={require('./styles')}
            >
                <div className="header">
                    <h2 className="title">
                        {title}
                    </h2>
                    <h3 className="subtitle">
                        {subtitle}
                    </h3>
                </div>
                <div className="options">
                    {typeOptions.map(typeOption => (
                        <div 
                            key={typeOption.value}
                            className={classnames(
                                'option',
                                {  active: typeOption.value === selectedOfferType }
                            )}
                            onClick={() => onSelectOfferType(typeOption.value)}
                        >
                            <span className="option-bg" />
                            <div className="option-content">
                                <h3 className="option-title">
                                    {typeOption.label}
                                </h3>
                                {Array.isArray(typeOption?.data?.features) && typeOption?.data?.features.length > 0 && (
                                    <div className="option-features">
                                        {typeOption?.data?.features.map(feature => (
                                            <span 
                                                className="option-feature" 
                                                key={feature}
                                            >
                                                {feature}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </StyledComponent>
        );
    }
}