import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    align-items: stretch;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: stretch;
        
    .headline {
        font-size: 1.5em;
        text-align: center;
        color: #EEEEEE;
        margin-bottom: 1em;
    }

    @media (max-width: ${variables.desktopM}) {
        
    }
    @media (max-width: ${variables.mobileL}) { }
`;
