import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;

    .component-public-reservations-slot-select-form {
        .body {
            .column {
                &.column-time {
                    .time-select {
                        margin-top: 0;
                    }
                }
            }
        }
    }


    @media (max-width: ${variables.tabletL}) {
        font-size: 90%;

        .component-public-reservations-slot-select-form {
            .body {
                .column {
                    &.column-time {
                        .time-select {
                            .slots-container {
                                .slots-category {
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: flex-start;

                                    .slot-category-label {
                                        margin-bottom: .5em;
                                    }
                                }
                            }
                        }
                    }

                    &.column-date {
                        margin-bottom: .5em;
                    }
                }
            }
        }
    }
`;
