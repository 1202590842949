import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;

    .options {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;

        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 3em;
            margin: 0 1em;
            border-radius: .3em;
            position: relative;
            padding: 2em;
            transition: .2s all ease-in-out;
            width: 100%;
            max-width: 23em;
            cursor: pointer;

            .option-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                transition: .2s all ease-in-out;
                box-shadow: 0 0 0 1em rgba(0, 0, 0 .2);
                border: 1px solid black;
                border-radius: .2em;
            }

            .option-content {
                position: relative;
                z-index: 3;

                .option-title {
                    font-size: 2.6em;
                    font-weight: 900;
                    color: black;
                }
            }
            
            &.active,
            &:hover {
                transform: scale(1.05);

                .option-bg {
                    border: 3px solid #FC2B1B;
                }

                .option-content {
                    .option-title {
                        color: #FC2B1B;
                    }
                }
            }
        }
    }
`;
