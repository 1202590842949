import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TrainingSelectForm from 'Components/public/reservations/SlotSelectForm';
import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

export default class PublicTrainingsAvailability extends Component {
    locationSlug = 'gabinet-masazu-radisson-sobieski';

    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            getLocationBySlug: PropTypes.func.isRequired,
        }).isRequired,
        onClose: PropTypes.func,
        selectedProduct: PropTypes.object,
    };
    
    static defaultProps = {
        data: null,
    };

    state = {
        date: moment().toDate(),
        selectedSlot: null,
        selectedLocation: null,
    };

    componentDidMount = () => {
        const { actions } = this.props;

        actions.getLocationBySlug({ slug: this.locationSlug })
            .then(response => {
                const location = response.payload.location;

                this.setState({
                    selectedLocation: location,
                });
            });
    }

    onTrainingSelectFormSubmit = data => {
        this.setState({ selectedSlot: data });
    }

    render() {
        const { location, history, selectedProduct } = this.props;
        const { selectedLocation } = this.state;

        if (!selectedLocation) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="public-trainings-availability"
                styles={require('./styles')}
            > 
                <TrainingSelectForm
                    location={location}
                    history={history}
                    selectedLocation={selectedLocation}
                    selectedProduct={selectedProduct}
                    onSubmit={this.onTrainingSelectFormSubmit}
                    previewMode={true}
                />
            </StyledComponent>
        );
    }
}