import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { PUBLIC_HOME } from 'Consts/routes';
import { PRODUCT_CATEGORY_SHOP_MAP } from 'Consts/products';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getStatusLabel } from 'Utils/userOffer';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';
import Button from 'Components/layout/Button';

export default class AdeptUserOffersVerticalList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        userOffers: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
        productCategory: PropTypes.string,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
    };

    state = {};

    defaultQuery = {
        page: 1,
        perPage: 10,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);
        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (
            JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject) ||
            JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)  //eslint-disable-line react/destructuring-assignment
        ) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    renderUserOffers = element => {
        const { productCategory, history } = this.props;
        const productCategoryMapElem = PRODUCT_CATEGORY_SHOP_MAP.find(productCategoryShopElem => productCategoryShopElem.shopSlug  === productCategory);

        return (
            <div className="elements-wrapper">
                <div className="element-header">
                    {element.name}
                </div>
                <div className="element-price">
                    <div className="element-price-header">
                        Cena
                    </div>
                    <div className="element-price-gross">
                        {element.totalPriceGross} zł
                    </div>
                </div>
                <div className="element-products">
                    {element.products.map(product => (
                        <div key={product?.id} className="product">
                            <div className="product-name">
                                {product.product.name}
                            </div>
                            <div className="product-quantity">
                                x{product.quantity}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="element-controls">
                    <Button
                        style="hollowWhite"
                        onClick={() => history.push(
                            withVariables(
                                productCategoryMapElem.shopPath,
                                {},
                                { userOfferId: element.id }
                            )
                        )}
                    >
                        Zmień
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const { 
            userOffers, 
            location, 
            history,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
        } = this.props;

        return (
            <StyledComponent
                className="adept-user-offers-vertical-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOffers}
                    queryConfig={this.getQueryConfig()}
                    styleVersion={2}
                    onMapElement={element => this.renderUserOffers(element)}
                    // onMapElement={element => (
                    //     <PaginatedListElement
                    //         key={element.id}
                    //         title={onMapTitle(element, element.name)}
                    //         subtitle={onMapSubTitle(element, `Status: ${getStatusLabel(element).label}`)}
                    //         additionals={onMapAdditionals(element, [])}
                    //         labels={onMapLabels(element, [])}
                    //         controls={onMapControls(element, [{
                    //             type: 'button',
                    //             label: 'Szczegóły',
                    //             visible: true,
                    //             to: withVariables(ADEPT_USER_OFFER.path, { id: element.id }),
                    //             style: 'gradient',
                    //         }])}
                    //     />
                    // )}
                    filters={onMapFilters([])}
                />
            </StyledComponent>
        );
    }
}
