import moment from 'moment';

import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        apiUrl: process.env.OLD_API_URL,
        path: endpoints.ENDPOINT_OLD_FORM_TRAINER_CALENDAR
            .replace('{id}', params.id)
            .replace('{startDate}', params.startDate || moment().format('YYYY-MM-DD')),
        params,
        requestParams: {
            gym: params.gym,
            days: params.days || 3,
        },
    }));
};
