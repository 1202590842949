import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { PAYMENT_TRANSACTION_TYPE_SINGLE, PAYMENT_TRANSACTION_TYPE_DEFAULT } from 'Consts/paymentTransactions';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import Checkbox from 'Components/forms/Checkbox';

export default class PublicShopPaymentCheckoutPaymentButton extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        paymentType: PropTypes.oneOf(['single', 'default']),
        paymentMethod: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        isPending: PropTypes.bool,
        validate: PropTypes.bool,
        buttonLabel: PropTypes.string,
        consentFitadeptTerms: PropTypes.bool,
        consentMarketingPhone: PropTypes.bool,
        consentMarketingEmail: PropTypes.bool,
        consentSelectAll: PropTypes.bool,
        onChangeConsentFitadeptTerms: PropTypes.func,
        onChangeConsentMarketingPhone: PropTypes.func,
        onChangeConsentMarketingEmail: PropTypes.func,
        onChangeConsentSelectAll: PropTypes.func,
        showConsents: PropTypes.bool,
        style: PropTypes.string,
    };
    static defaultProps = {
        style: 'gradient',
        paymentType: null,
        paymentMethod: null,
        validate: true,
        buttonLabel: 'Płacę',
        showConsents: true,
        consentFitadeptTerms: false,
        consentMarketingPhone: false,
        consentMarketingEmail: false,
        consentSelectAll: false,
        onChangeConsentFitadeptTerms: () => true,
        onChangeConsentMarketingPhone: () => true,
        onChangeConsentMarketingEmail: () => true,
        onChangeConsentSelectAll: () => true,
    };
    state = {};

    onSubmit = () => {
        const { onSubmit, consentFitadeptTerms, showConsents } = this.props;

        if(showConsents && !consentFitadeptTerms) {
            return toast.error('Zaznacz wymagane zgody');
        }

        return onSubmit();
    }

    render() {
        const { 
            paymentType,
            paymentMethod,
            onSubmit,
            isPending,
            validate,
            buttonLabel,
            consentFitadeptTerms,
            consentMarketingPhone,
            consentMarketingEmail,
            onChangeConsentFitadeptTerms,
            onChangeConsentMarketingPhone,
            onChangeConsentMarketingEmail,
            onChangeConsentSelectAll,
            consentSelectAll,
            showConsents,
            style,
        } = this.props;

        let isValid = false;
        if(paymentType === PAYMENT_TRANSACTION_TYPE_DEFAULT && paymentMethod) {
            isValid = true;
        }
        if(paymentType === PAYMENT_TRANSACTION_TYPE_SINGLE) {
            isValid = true;
        }

        return (
            <StyledComponent
                className="public-shop-payment-checkout-payment-button"
                styles={require('./styles')}
            >
                <div className="block block-confirm">
                    <Button
                        size="large"
                        layout="fullWidth"
                        style={style}
                        onClick={onSubmit}
                        loading={isPending}
                        disabled={validate ? !isValid : false}
                    >
                        {buttonLabel}
                    </Button>
                    {showConsents && (
                        <div className="consents">
                            <Checkbox
                                label="Zaznacz wszystkie zgody"
                                name="consentSelectAll"
                                value={consentSelectAll}
                                onChange={data => onChangeConsentSelectAll(data.value)}
                                styleVersion={1}
                            />
                            <Checkbox
                                label="Przeczytałem i akceptuję regulamin sklepu <a href='https://fitadept.com/regulamin' target='_blank'>Więcej</a>"
                                name="consentFitadeptTerms"
                                required={true}
                                value={consentFitadeptTerms}
                                onChange={data => onChangeConsentFitadeptTerms(data.value)}
                                styleVersion={1}
                            />
                            <Checkbox
                                label="Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną."
                                name="consentMarketingEmail"
                                value={consentMarketingEmail}
                                onChange={data => onChangeConsentMarketingEmail(data.value)}
                                styleVersion={1}
                            />
                            <Checkbox
                                label="Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu."
                                name="consentMarketingPhone"
                                value={consentMarketingPhone}
                                onChange={data => onChangeConsentMarketingPhone(data.value)}
                                styleVersion={1}
                            />
                        </div>
                    )}
                </div>
            </StyledComponent>
        );
    }
}