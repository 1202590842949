import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_USER_OFFERS } from 'Consts/apiResources';
import { USER_OFFER_STATUS_NEW, USER_OFFER_STATUS_ACTIVE } from 'Consts/userOffers';
import { PRODUCT_CATEGORY_SHOP_MAP } from 'Consts/products';
import { OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Spinner from 'Components/layout/Spinner';
import UserOffersList from 'Components/adept/userOffers/VerticalList';
import Button from 'Components/layout/Button';

export default class PublicShopDuplicatedUserOfferWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        children: PropTypes.any,
        productCategory: PropTypes.string.isRequired,

        // Redux props
        userOffersList: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        children: null,
    };
    state = {
        isValidated: false,
        validationStep: null,
        duplicatedProductCategoryUserOffers: [],
    };

    componentDidMount = () => {
        const { location } = this.props;
        const queryObject = parseQueryToObject(location.search);

        if(queryObject.userOfferId) {
            this.setState({ isValidated: true });
        } else {
            this.validateUserOffer();
        }
    }

    validateUserOffer = () => {
        let { actions, productCategory } = this.props;

        const productCategoryMapElem = PRODUCT_CATEGORY_SHOP_MAP.find(productCategoryShopElem => productCategoryShopElem.shopSlug === productCategory);
        actions.list({ 
            page: 1, 
            perPage: 999,
            statuses: [USER_OFFER_STATUS_ACTIVE],
            productCategorySlug: productCategoryMapElem?.productCategorySlug,
            periodType: OFFER_PERIOD_TYPE_RECURRING,
        })
            .then(response => {
                let userOffers = response.payload[API_RESOURCE_USER_OFFERS].elements;
                const duplicatedProductCategoryUserOffers = userOffers.filter(userOfferElem => userOfferElem?.productCategory?.slug === productCategoryMapElem?.productCategorySlug);
                
                if(duplicatedProductCategoryUserOffers.length > 0) {
                    this.setState({ 
                        validationStep: 'userOfferSelect',
                        duplicatedProductCategoryUserOffers,
                    });
                } else {
                    this.setState({ 
                        validationStep: null,
                        duplicatedProductCategoryUserOffers: [],
                        isValidated: true,
                    });
                }
            });
    }

    getProductsAdditonals = (userOffer) => {
        return userOffer?.products?.map(product => ({
            name: product?.product?.name,
            value: 'x' + product?.quantity,
        })) || [];
    }

    render() {
        const { children, location, history, productCategory } = this.props;
        const { isValidated, validationStep, duplicatedProductCategoryUserOffers } = this.state;
        const queryObject = parseQueryToObject(location.search);
        const productCategoryMapElem = PRODUCT_CATEGORY_SHOP_MAP.find(productCategoryShopElem => productCategoryShopElem.shopSlug  === productCategory);

        if(queryObject.userOfferId || isValidated) {
            return children;
        }

        let validationStepMarkup = (<Spinner />);
        switch (validationStep) {
            case 'userOfferSelect':
                validationStepMarkup = (
                    <LayoutContainer>
                        <h1 className="headline">
                            Posiadasz już zamówienie o takiej kategorii, czy chcesz zmienić jedno z już istniejących zamiast tworzyć kolejne?
                        </h1>
                        <UserOffersList
                            location={location}
                            history={history}
                            productCategory={productCategory}
                            onMapElement={duplicatedProductCategoryUserOffers}
                            predefinedQuery={{
                                page: 1, 
                                perPage: 999,
                                statuses: [USER_OFFER_STATUS_NEW, USER_OFFER_STATUS_ACTIVE],
                                productCategorySlug: productCategoryMapElem?.productCategorySlug,
                                periodType: OFFER_PERIOD_TYPE_RECURRING,
                            }}
                            onMapAdditionals={(elem) => ([
                                {
                                    name: 'Cena',
                                    value: formatPrice(elem.totalPriceGross),
                                },
                                ...this.getProductsAdditonals(elem),
                            ])}
                            onMapControls={(elem) => ([{
                                type: 'button',
                                label: 'Zmień',
                                visible: true,
                                onClick: () => {
                                    history.push(
                                        withVariables(
                                            productCategoryMapElem.shopPath,
                                            {},
                                            { userOfferId: elem.id }
                                        )
                                    ).then(
                                        this.setState({
                                            isValidated: true,
                                        }),
                                    );
                                },
                                style: 'gradient',
                            }])}
                        />
                        <div className="checkout-wrapper">
                            <h2 className="checkout-wrapper-headline">
                                Tak, chcę utworzyć nowe zamówienie 
                            </h2>
                            <Button 
                                onClick={() => this.setState({ isValidated: true })}
                                size="extraLarge"
                                style="gradient"
                            >
                                Stwórz nowe zamówienie
                            </Button>
                        </div>
                    </LayoutContainer>
                );
                break;
            default:
                break;
        }
        
        return (
            <StyledComponent
                className="public-shop-duplicated-user-offer-wrapper"
                styles={require('./styles')}
            >
                {validationStepMarkup}
            </StyledComponent>
        );
    }
}
