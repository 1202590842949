import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const check = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHECK,
        method: 'POST',
        apiUrl: process.env.OLD_API_URL,
        path: endpoints.ENDPOINT_OLD_FORM_COUPON_CHECK,
        params,
        requestParams: {
            code: params.code,
            productId: params.productId,
        },
    }));
};
