import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;

    @media (max-width: ${variables.tabletL}) {
        width: 90vw;

        .modal-body-component {
            padding: 1.5em;
        }
    }
`;
